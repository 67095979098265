import React from 'react'
import { ViewProduct, VisitPoint } from '../act-1'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap = ({
  height,
  width,
  setActiveProduct,
  hoverProduct,
  history = {},
  setPointInfo
}) => {
  const [hovered, setHover] = React.useState('')
  const findProduct = (id) => catalog.find((x) => x.id === id)
  const clickIn = (id) => {
    // hoverProduct && setHover()
    // if (history?.push && id?.startsWith('visit-')) { history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    hoverProduct && setHover(id)
    setActiveProduct && setActiveProduct(findProduct(id))
  }
  const hoverIn = (id) => {
    if (hovered === id) return
    hoverProduct && setHover(id)
    setActiveProduct && setActiveProduct(findProduct(id))
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 218 151'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className='no-touch'
        id='Path 725'
        d='M111.5 146.68L10 146.68'
        stroke='#545454'
        strokeWidth='7'
      />
      <ViewProduct hovered={hovered} hoverIn={hoverIn} name='act3-chloe-sofa'>
        <path
          className='transparent'
          d='M50.5439 98.8808C55.3989 100.139 58.9441 100.825 61.1221 110.098C61.9256 113.382 63.3862 116.47 65.416 119.174C67.4458 121.878 70.0023 124.142 72.9316 125.831C76.9711 128.038 81.5104 129.169 86.1133 129.114H113.831H161.983'
          stroke='#545454'
        />
        <path
          d='M161.823 138.599C142.083 138.599 96.8321 138.943 82.8691 138.599C68.9061 138.255 60.8893 128.527 57.1123 124.406C53.3353 120.285 48.1844 103.46 51.7334 95.9057C55.2824 88.3517 66.3836 79.0799 79.8916 82.8579C93.3996 86.6359 96.6032 96.3641 100.492 101.514C104.381 106.664 113.655 107.014 113.655 107.014H161.821L161.823 138.599Z'
          stroke='#545454'
        />
      </ViewProduct>

      <ViewProduct hovered={hovered} hoverIn={hoverIn} name='act3-eva-armchair'>
        <path
          d='M174.852 26.9072H184.997C186.294 26.9071 187.577 27.1623 188.775 27.6583C189.973 28.1543 191.061 28.8814 191.978 29.798C192.894 30.7146 193.622 31.8029 194.118 33.0005C194.614 34.1982 194.869 35.4819 194.869 36.7782V36.7782C194.869 38.0745 194.614 39.3581 194.118 40.5557C193.622 41.7533 192.895 42.8415 191.978 43.7581C191.062 44.6747 189.973 45.4018 188.776 45.8978C187.578 46.3939 186.295 46.6492 184.998 46.6492H174.852C173.761 46.6492 172.715 46.2159 171.944 45.4446C171.173 44.6732 170.739 43.6271 170.739 42.5362V31.0202C170.739 29.9294 171.173 28.8832 171.944 28.1119C172.715 27.3406 173.761 26.9072 174.852 26.9072V26.9072Z'
          stroke='#545454'
        />
        <path
          d='M174.852 56.3018H184.997C186.294 56.3016 187.577 56.5569 188.775 57.0529C189.973 57.5489 191.061 58.2759 191.978 59.1926C192.894 60.1092 193.622 61.1974 194.118 62.3951C194.614 63.5927 194.869 64.8764 194.869 66.1728V66.1728C194.869 67.469 194.614 68.7526 194.118 69.9502C193.622 71.1478 192.895 72.236 191.978 73.1526C191.062 74.0692 189.973 74.7963 188.776 75.2924C187.578 75.7884 186.295 76.0438 184.998 76.0438H174.852C173.761 76.0438 172.715 75.6104 171.944 74.8391C171.173 74.0678 170.739 73.0216 170.739 71.9308V60.4148C170.739 59.3239 171.173 58.2778 171.944 57.5064C172.715 56.7351 173.761 56.3018 174.852 56.3018V56.3018Z'
          stroke='#545454'
        />
      </ViewProduct>

      <ViewProduct
        hovered={hovered}
        hoverIn={hoverIn}
        name='act3-panton-coffee-table'
      >
        <path
          d='M98.4398 57.3351C98.4398 66.3749 105.768 73.7031 114.808 73.7031C123.848 73.7031 131.176 66.3749 131.176 57.3351V56.7401C131.176 47.7003 123.848 40.3721 114.808 40.3721C105.768 40.3721 98.4398 47.7003 98.4398 56.7401V57.3351Z'
          stroke='#545454'
        />
      </ViewProduct>

      <ViewProduct hovered={hovered} hoverIn={hoverIn} name='act3-pouf-rachel'>
        <path
          d='M32.103 58.5566C28.0177 58.5566 24.706 61.8683 24.706 65.9536C24.706 70.0388 28.0177 73.3506 32.103 73.3506C36.1883 73.3506 39.5 70.0388 39.5 65.9536C39.5 61.8683 36.1883 58.5566 32.103 58.5566Z'
          stroke='#545454'
        />
      </ViewProduct>

      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={25}
      >
        <path
          d='M199.145 85.0312C196.659 85.0312 194.645 87.046 194.645 89.5312C194.645 92.0165 196.659 94.0312 199.145 94.0312C201.63 94.0312 203.645 92.0165 203.645 89.5312C203.645 87.046 201.63 85.0312 199.145 85.0312Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={24}
      >
        <path
          d='M151.323 49.0557C148.838 49.0557 146.823 51.0704 146.823 53.5557C146.823 56.0409 148.838 58.0557 151.323 58.0557C153.809 58.0557 155.823 56.0409 155.823 53.5557C155.823 51.0704 153.809 49.0557 151.323 49.0557Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={23}
      >
        <path
          d='M138.601 85.4697C136.115 85.4697 134.101 87.4844 134.101 89.9697C134.101 92.455 136.115 94.4697 138.601 94.4697C141.086 94.4697 143.101 92.455 143.101 89.9697C143.101 87.4844 141.086 85.4697 138.601 85.4697Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={21}
      >
        <path
          d='M83.3223 65.7275C80.837 65.7275 78.8223 67.7423 78.8223 70.2275C78.8223 72.7128 80.837 74.7275 83.3223 74.7275C85.8075 74.7275 87.8223 72.7128 87.8223 70.2275C87.8223 67.7423 85.8075 65.7275 83.3223 65.7275Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={22}
      >
        <path
          d='M33.3076 93.8057C30.8223 93.8057 28.8076 95.8204 28.8076 98.3057C28.8076 100.791 30.8223 102.806 33.3076 102.806C35.7929 102.806 37.8076 100.791 37.8076 98.3057C37.8076 95.8204 35.7929 93.8057 33.3076 93.8057Z'
          fill='#339966'
        />
      </VisitPoint>
    </svg>
  )
}

export const ActMap3 = React.memo(ActMap)
