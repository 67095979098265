import React from 'react'
import ForwardToSocialNetworkIcon from './forward-to-sn-icon'
import PropTypes from 'prop-types'

const ForwardIcon = ({ fill, stroke }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' style={{ cursor: 'pointer' }}>
      <g id='forward' transform='translate(44 44) rotate(180)'>
        <g id='Rectangle_1' data-name='Rectangle 1' transform='translate(44 44) rotate(180)' fill={fill} stroke={stroke} strokeWidth='1'>
          <rect width='44' height='44' stroke='none' />
          <rect x='0.5' y='0.5' width='43' height='43' fill='none' />
        </g>
        <path id='Path_295' data-name='Path 295' d='M0,7,7,0l7,7' transform='translate(18 29) rotate(-90)' fill='none' stroke={stroke} strokeWidth='2' />
      </g>
    </svg>
  )
}

ForwardIcon.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string
}

ForwardIcon.defaultProps = {
  fill: 'rgba(0,0,0,0.75)',
  stroke: '#fff'
}

export {
  ForwardIcon,
  ForwardToSocialNetworkIcon
}
