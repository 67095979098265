import {
  all
} from 'redux-saga/effects'

import visitorSagas from './api/visitor'
import wishlistSagas from './api/wishlist'
import contactSagas from './api/contacts'

export default function * rootSaga () {
  yield all([
    visitorSagas(),
    wishlistSagas(),
    contactSagas()
  ])
}
