import React from 'react'

const ShareIcon = ({ fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'>
      <g id='Group_411' data-name='Group 411' transform='translate(0.5 0.5)'>
        <rect id='Rectangle_273' data-name='Rectangle 273' width='34' height='34' fill='none' stroke='#2a2a2a' strokeWidth='1' opacity='0.1' />
        <path id='Path_223' data-name='Path 223' d='M1825.8,433.436l2.238,5.513L1834,425.028Zm7.529-9.487L1819,430.423l5.969,2.1Z' transform='translate(-1809.5 -414.449)' fill='#2a2a2a' />
      </g>
    </svg>
  )
}

export default ShareIcon
