// import React from "react";
// import { connect } from 'react-redux';
// import { store } from './../../effects/store'
// import { updateCurrentActSong, updateMuted } from './../../effects/actions'
// import { eventFire } from "../../utils";

// export const setAudioMuted = () => (store.dispatch(updateMuted()))
// export const currentActSong = () => (store.getState().app.currentActSong)

// const PlayerComponent = ({ currentActSong, muted }) => {
//   const audioRef = React.useRef()
//   const soungSource = currentActSong ? currentActSong.source : "act-00.mp3"
//   const src = require(`./../../assets/musics/${soungSource}`)
//   React.useEffect(() => {
//     if (!audioRef.current) return;
//     if (!window.audioRef) window.audioRef = audioRef.current
//     eventFire(document.getElementById('root'), 'click');
//     audioRef.current.muted = true;
//     audioRef.current.src = src;
//     audioRef.current.muted = muted;
//     audioRef.current.play();
//   }, [currentActSong, muted, src])
//   return <audio ref={audioRef} muted={muted} loop src={src} preload="auto" />
// };
// const mapStateToProps = ({ app }) => {
//   const { currentActSong, muted } = app
//   return { currentActSong, muted }
// }

// export default connect(mapStateToProps, { updateCurrentActSong, updateMuted })(React.memo(PlayerComponent));

import React from 'react'
import { connect } from 'react-redux'
import { store } from './../../effects/store'
import { updateCurrentActSong, updateMuted } from './../../effects/actions'

export const setAudioMuted = () => (store.dispatch(updateMuted()))
export const currentActSong = () => (store.getState().app.currentActSong)

const PlayerComponent = ({ currentActSong, muted }) => {
  const audioRef = React.useRef()
  const soungSource = currentActSong ? currentActSong.source : 'act-00.mp3'
  const src = require(`./../../assets/musics/${soungSource}`)
  React.useEffect(() => {
    if (!audioRef.current) return
    if (!window.audioRef) window.audioRef = audioRef.current
    // audioRef.current.muted = muted;
    // audioRef.current.src = src;
    // audioRef.current.volume = 0.5;
  }, [currentActSong, muted, src])
  return <audio ref={audioRef} autoPlay muted={muted} loop src={src} />
  // return <audio ref={audioRef} autoPlay muted loop />
}
const mapStateToProps = ({ app }) => {
  const { currentActSong, muted } = app
  return { currentActSong, muted }
}

export default connect(mapStateToProps, { updateCurrentActSong, updateMuted })(React.memo(PlayerComponent))
