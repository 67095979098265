/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { connect } from 'react-redux'
import { StyledDiv, StyledP, StyledPIn, StyledImg, CenterContent } from './show-player.styled'

const ShowPlayerComponent = ({ show, currentActSong }) => {
  if (currentActSong) {
    return (
      <StyledDiv show={show}>
        <StyledImg src={require(`./../../assets/cover/${currentActSong.image}`)} />
        {/* <StyledImg src={require(`./../../assets/cover/default.png`)} /> */}
        <CenterContent>
          <StyledP>{currentActSong.singerName}</StyledP>
          <StyledPIn>{currentActSong.title}</StyledPIn>
        </CenterContent>

      </StyledDiv>
    )
  } else {
    return (
      <StyledDiv show={show}>
        <StyledImg src={require('./../../assets/cover/default.png')} />
        <CenterContent>
          <StyledPIn reset>unknown</StyledPIn>
          <StyledP reset>unknown</StyledP>
        </CenterContent>
      </StyledDiv>
    )
  }
}

const mapStateToProps = ({ app }) => {
  const { currentActSong } = app
  return { currentActSong }
}

export default connect(mapStateToProps, null)(React.memo(ShowPlayerComponent))
