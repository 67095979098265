import React from 'react'
import PropTypes from 'prop-types'
import { StyledLi, StyledTitle, StyledUl } from './navigation-disclaimer.styled'
import { ChatIcon, HeartIcon, LogoIcon } from './icons'

export const NavigationDisclaimer = React.memo(({ mobile }) => (
  <div>
    <StyledTitle mobile={mobile}><span>Navigation</span></StyledTitle>
    <StyledUl mobile={mobile}>
      <StyledLi mobile={mobile}>
        <LogoIcon /> Click to view our products
      </StyledLi>
      <StyledLi mobile={mobile}>
        <HeartIcon /> Add products to your wishlist
      </StyledLi>
      <StyledLi mobile={mobile}>
        <ChatIcon /> Chat with us anytime
      </StyledLi>
    </StyledUl>
  </div>
))

NavigationDisclaimer.propTypes = {
  mobile: PropTypes.bool
}

NavigationDisclaimer.defaultPropTypes = {
  mobile: false
}
