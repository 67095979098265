/* eslint-disable react-hooks/exhaustive-deps */
import * as THREE from 'three'
import React from 'react'
import styled from 'styled-components'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import { VRCanvas, DefaultXRControllers, Hover } from '@react-three/xr'
import { VRButton } from './VRButton'
import { Html } from '@react-three/drei'
import { useHistory } from 'react-router'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import LeftMap from './LeftMap'
import { Walkspot } from '../../assets'
import LoadingPage from '../../pages/loading'
import { ChatIcon, HotspotInfoIcon, HotspotProductIcon } from '../'
import { isMobile } from '../../utils'

const FOV = window.visualViewport?.width < 600 ? 90 : 60
const SPEED = -0.3
const AUTO_ROTATE_SPEED = 0.3
extend({ OrbitControls })

const InfoTitle = styled(ReactMarkdown)`
    p{  
        color:#FFF; 
        font-family:'Didot LT Pro'; 
        font-size:10px;
        text-align:center;
    }
`
const InfoDescription = styled(ReactMarkdown)`
    p, span { 
        text-align: left;
        color: #FFF;
        font-family:DIN2014;
        font-size:9px;
        margin-bottom: 0;
    }
`

const Info = styled(Html)`
    background-color:rgb(0 0 0 / 75%);
    height: auto;
    min-width: 170px;
    max-width: 400px;
    padding: 16px;
    position: relative;
    right: 0px;
    bottom: 0px;
`
const Video = styled(Html)`
    background-color:rgb(0 0 0 / 75%);
    height: auto;
    min-width: 500px;
    max-height: 350px;
    padding: 8px;
    position: relative;
    right: 0px;
    bottom: 0px;
    > div{
        height: 100vh;
        max-height: 324px;
    }
`

const ButtonProduct = styled.div`
 svg{
     &:hover{
        cursor: pointer;
        .border{
            fill: #333;
        }
     }
 }
`
const ButtonWall = styled.div`
    display: flex;
    justify-content: center;
    min-height: 25px;
    max-height: 40px;
    min-width: 60px;
    max-width: 90px;
    border: none;
    height: auto;
    background-color: rgba(0,0,0,0.65);
    font-size: 6px;
    line-height: 1.4;
    font-family:Radnika-Regular;
    color: #E9E9E9;
    text-transform: uppercase;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    :focus,:hover {
        outline: none;
        background-color: #703540;
        cursor: pointer;
    }
`

const Controls = React.memo(({ initialPosition, initialCompass = 0, autoRotate }) => {
  const mounted = React.useRef()
  const orbitRef = React.useRef()
  const { gl, camera } = useThree()
  useFrame(() => updateCamera())

  if (orbitRef.current && mounted.current !== true) {
    window.controls = orbitRef.current
    window.gl = gl
    camera.fov = FOV
    gl.setSize(window.innerWidth, window.innerHeight, false)
    mounted.current = true
    camera.updateProjectionMatrix()
  }

  const updateCamera = () => {
    if (!window.controls) window.controls = orbitRef.current
    if (!window.gl) window.gl = gl
    orbitRef.current && orbitRef.current.update()
    camera.updateProjectionMatrix()

    if (!window.compass) return
    const rotateX = isMobile() ? 0 : initialCompass[0] ?? 0
    const rotateY = isMobile() ? 0 : initialCompass[1] ?? 0
    const newTransform = `translateX(${rotateX}px) translateY(${rotateY}px)`
    // console.log(rotateX, rotateY)
    if (window.compass.style.transform === newTransform) return
    window.compass.style.transform = newTransform
  }

  return (
    <orbitControls
      ref={orbitRef}
      args={[camera, gl.domElement]}
      maxPolarAngle={2}
      minPolarAngle={1}
      minDistance={FOV}
      maxDistance={FOV}
      enableKeys
      enableZoom
      enablePan
      enableDamping
      dampingFactor={0.06}
      autoRotate={autoRotate}
      rotateSpeed={SPEED}
      autoRotateSpeed={AUTO_ROTATE_SPEED}
    />
  )
})
const createVRCanvas = ({ gl }) => (VRButton.createXR(gl))
const VRComponent = ({ vr = false, panorama, hotspots = [], initialPosition = [0, 0, 0], initialCompass = 0, rotate, currentAct, previewPanorama }) => {
  const vrRef = React.useRef()
  const history = useHistory()
  const [loaded, setLoaded] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const camera = new THREE.PerspectiveCamera(FOV, window.innerWidth / window.innerHeight, 0.1, 1500)
  window.camera = camera
  if (window.controls) { if (window.controls.autoRotate !== rotate) { window.controls.autoRotate = rotate; camera.updateProjectionMatrix() } }

  React.useEffect(() => { camera.position.set(...initialPosition) }, [camera])
  React.useEffect(() => { camera.position.set(...initialPosition) }, [initialPosition])
  React.useEffect(() => {
    loaded && setLoaded(false);
    (!loading && !loaded) && setLoading(true)
  }, [panorama])
  React.useEffect(() => {
    const t = setTimeout(() => {
      if (loaded) {
        setLoading(false)
        setLoaded(false)
      }
    }, 3000)
    return () => clearTimeout(t)
  }, [panorama])

  const opacityStyle = (loading && loaded === false) ? 'opacity-05' : 'preview-invisible'

  return (
    <div className='fullscreen'>
      <button ref={vrRef} id='VRButton' className='d-none' />
      <LoadingPage className={opacityStyle} forceLogoOnly redirect={false} />
      {(loading && loaded === false) && <div className='absolute-100 h-100 w-100 overflow-hidden zindex-10' style={{ backgroundImage: `url(${previewPanorama})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} />}
      <div className='gradient-fullscreen' />
      <LeftMap currentAct={currentAct} />
      {vr && (
        <VRCanvas onCreated={createVRCanvas} camera={camera} pixelRatio={window.devicePixelRatio} webgl1 colorManagement={false}>
          <Controls initialPosition={initialPosition} initialCompass={initialCompass} autoRotate={rotate} />
          <DefaultXRControllers />
          <Main map={panorama} loaded setLoaded={(event) => { }} />
          {hotspots?.filter(x => x.type === 'panorama-spot').map(x => ({ ...x, size: [120, 120] })).map((hotspot, i) => (
            <Hover key={i} onChange={(e) => { if (e.controller.hoverRayLength > 40 && e.controller.hovering) onClickHotspot(hotspot, history) }}>
              <Hotspot square history={history} hotspot={hotspot} />
            </Hover>
          ))}
        </VRCanvas>
      )}
      {!vr && (
        <Canvas camera={camera} pixelRatio={window.devicePixelRatio} colorManagement={false}>
          <Controls initialPosition={initialPosition} initialCompass={initialCompass} autoRotate={rotate} />
          {hotspots?.filter(x => x.geometry === 'square').map((hotspot, i) => (<Hotspot square history={history} hotspot={hotspot} key={i} />))}
          {hotspots?.filter(x => x.geometry !== 'square').map((hotspot, i) => (<Hotspot circle history={history} hotspot={hotspot} key={i} />))}
          <Main map={panorama} loaded={loaded} setLoaded={setLoaded} />
        </Canvas>
      )}
    </div>
  )
}
export default React.memo(VRComponent)

const Main = React.memo(({ loaded, map, setLoaded }) => {
  if (!map) return <mesh />
  const geometry = new THREE.SphereBufferGeometry(500, 60, 40)
  const texture = new THREE.TextureLoader()
    .load(map,
      (texture) => {
        setLoaded && setLoaded(true)
      }
    )
  texture.minFilter = THREE.LinearFilter
  texture.magFilter = THREE.LinearFilter
  const debugXYZ = false
  return (
    <mesh geometry={geometry} position={[0, 0, 0]} scale={[1, 1, -1]} onPointerMove={(e) => debugXYZ && console.log('canvas', [e.point.x.toFixed(0), e.point.y.toFixed(0), e.point.z.toFixed(0)])}>
      <meshBasicMaterial visible={loaded} map={texture} side={THREE.BackSide} opacity={1} transparent={false} needsUpdate={false} />
    </mesh>
  )
})

const onClickHotspot = (hotspot, history) => hotspot.to && history && history.push(hotspot.to, history.location.pathname)

const Hotspot = React.memo(({ square, circle, history, hotspot }) => {
  const [hovered, setHover] = React.useState(false)
  const onOverHotspot = () => hotspot.hover && setHover(true)
  const onOutHotspot = () => hotspot.hover && setHover(false)
  const onClickChat = () => { hotspot.chat && window.$_Tawk && window.$_Tawk.toggle() }
  const scale = hovered ? hotspot.hoverSize : (hotspot.size ?? [-50, 50])
  const position = hotspot.position ?? [0, 0, 0]
  const rotation = hotspot.rotation ?? [0, 0, 0]

  if (hovered) {
    document.getElementsByTagName('body')[0].className = 'pointer'
  } else {
    document.getElementsByTagName('body')[0].className = ''
  }

  if (hotspot.type === 'panorama-spot') {
    return (
      <mesh
        key={hotspot.name}
        className='canvas-hover pointer'
        onPointerOver={(e) => { !hovered && setHover(true) }}
        onPointerOut={(e) => { hovered && setHover(false) }}
        onPointerDown={() => onClickHotspot(hotspot, history)}
        onClick={() => onClickHotspot(hotspot, history)}
        position={position}
        rotation={rotation}
        scale={scale}
      >
        {square && <planeBufferGeometry attach='geometry' args={[1, 1]} />}
        {circle && <circleGeometry attach='geometry' args={[0.5, 50]} />}
        <meshBasicMaterial map={new THREE.TextureLoader().load(Walkspot)} side={THREE.DoubleSide} blending={THREE.AdditiveBlending} opacity={hovered ? 1 : 0.4} transparent needsUpdate={false} />
      </mesh>
    )
  }
  return (
    <mesh
      key={hotspot.name}
      onPointerOver={onOverHotspot}
      onPointerOut={onOutHotspot}
      className='canvas-hover'
      position={position}
      rotation={rotation}
      scale={scale}
    >
      {(hotspot.type === 'info' && hovered) && (
        <Info scaleFactor={1000} zIndexRange={[1, 0]}>
          {hotspot?.title && <InfoTitle source={hotspot?.title} escapeHtml={false} />}
          <InfoDescription source={hotspot?.text} escapeHtml={false} />
        </Info>
      )}

      {(hotspot.type === 'video' && hovered) && (
        <Video scaleFactor={1000} zIndexRange={[1, 0]}>
          <div dangerouslySetInnerHTML={{ __html: hotspot.video }} />
        </Video>
      )}

      <Html scaleFactor={hotspot.scale || 1000} zIndexRange={[3, 0]} center>
        {(hotspot.type === 'info') && (<div className='info-icon' onClick={() => hotspot.hover && setHover(!hovered)}><HotspotInfoIcon /></div>)}

        {(hotspot.type === 'video') && (<div className='info-icon' onClick={() => hotspot.hover && setHover(!hovered)}><HotspotInfoIcon /></div>)}

        {(hotspot.type === 'link') && (<div className='info-icon' onClick={() => window.open(hotspot.to, '_blank')}><HotspotInfoIcon /></div>)}

        {(hotspot.type === 'product-button') && (
          <ButtonProduct onPointerOver={onOverHotspot} onPointerOut={onOutHotspot} onPointerDown={() => onClickHotspot(hotspot, history)} onClick={() => onClickHotspot(hotspot, history)} className='hotspot-product-icon'>
            <HotspotProductIcon />
          </ButtonProduct>
        )}

        {(hotspot.type === 'chat') && (<div className='chat-icon' onClick={onClickChat}><ChatIcon /></div>)}

        {(hotspot.type === 'wall-button') && (
          <ButtonWall onPointerOver={onOverHotspot} onPointerOut={onOutHotspot} onPointerDown={() => onClickHotspot(hotspot, history)} onClick={() => onClickHotspot(hotspot, history)}>
            {hotspot?.arrow === 'left' && <FontAwesomeIcon icon={faChevronLeft} color='#fff' size='2x' className='mr-2' />}
            {hotspot?.label}
            {hotspot?.arrow === 'right' && <FontAwesomeIcon icon={faChevronRight} color='#fff' size='2x' className='ml-2' />}
          </ButtonWall>
        )}
      </Html>
    </mesh>
  )
})
