import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import { store } from './effects/store'
import App from './app'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './utils.css'

const GlobalStyle = createGlobalStyle`
  @font-face { font-family: 'Catamaran';  src: url(https://fonts.gstatic.com/s/catamaran/v7/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd5a7duw.woff2) format('woff2'); unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
  @font-face { font-family:FuturaPT;       src: local(FuturaPT-Bold), url(./fonts/FuturaPT-Bold.woff) format('woff'); }
  @font-face { font-family:Din-Condensed;   src: local(Din-Condensed),   url('/fonts/Din-Condensed.woff')    format('woff');  font-display: swap; unicode-range: U+000-5FF; }
  @font-face { font-family:GFS-Didot;       src: local(GFS-Didot),       url('/fonts/GFS-Didot.woff2')       format('woff2'); font-display: swap; unicode-range: U+000-5FF; }
  @font-face { font-family:Didot;           src: local(Didot),           url('/fonts/Didot.woff2')           format('woff2'); font-display: swap; unicode-range: U+000-5FF; }
  @font-face { font-family:DIN2014;         src: local(DIN2014),         url('/fonts/DIN2014.woff2')         format('woff2'); font-display: swap; unicode-range: U+000-5FF; }  
  @font-face { font-family:Radnika-Bold;    src: local(Radnika-Bold),    url('/fonts/Radnika-Bold.woff2')    format('woff2'); font-display: swap; unicode-range: U+000-5FF; }
  @font-face { font-family:Radnika-Regular; src: local(Radnika-Regular), url('/fonts/Radnika-Regular.woff2') format('woff2'); font-display: swap; unicode-range: U+000-5FF; }


  *::-webkit-scrollbar { width: 2px } *::-webkit-scrollbar-track { background: #E2E2E2 } *::-webkit-scrollbar-thumb { background: #707070 }
  * { margin: 0; padding: 0 }
  html,body {background:#0a0a0a; font-family:Didot; font-size:14px;height:100vh }
  .enter-tour-button { font-family: Radnika-Regular !important }
  .background-lite{background-color: #F4F4F4!important} 
  .background-dark{background-color:#0a0a0a!important}
  .background-light{background-color: #F4F4F4 !important}
  .ant-btn:hover,.ant-btn:focus{color:#fff}
  .ant-drawer-body{padding:0}
  .ant-drawer-content{background:#F4F4F4; position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden}
  .ant-drawer-body{display: flex; flex-direction: column;}
  .ant-checkbox-checked .ant-checkbox-inner{background-color:#339966;border-color:#339966}.ant-checkbox-checked::after{border:1px solid #339966}
  .ant-tooltip{line-height:1.25} .ant-tooltip-arrow-content{background: #0a0a0a} .ant-tooltip-inner{min-height: 20px;padding: 4px 10px;background: #0a0a0a; border-radius:0; font-family:DIN2014}
  .ant-tooltip-arrow{bottom: -6px;width: 16px;height: 16px}.ant-tooltip-arrow-content{background:#0a0a0a;width:8px;height: 8px;}  
  .ant-select-dropdown,.ant-select-item-option-content{font-family:DIN2014; font-size:14px}
  .ant-select{background:transparent; width: 100%; margin-left:4px; margin-right:8px;top:-2px} .ant-select-arrow{margin-top:-10px} .ant-select-selector{padding: 0 4px !important}; .ant-select-selection-search{left:4px;right:4px!important} .ant-select-selection-placeholder{padding: 0 20px 0 0 !important} .anticon{font-size:15px; width:14px;}}
  .ant-select-selector,.ant-input,.ant-input:hover,.ant-input:focus{border:none;border-radius:0;border-bottom:1px solid #C4C4C4;border-right-width:0!important;box-shadow:none!important;caret-color:#339966!important; }
  .ant-input{background:transparent; border-bottom:1px solid #C4C4C4; :focus,:hover{border-bottom:1px solid #339966}}
  .ant-select{border-bottom:1px solid #C4C4C4; :focus,:hover{border-bottom:1px solid #339966}} 
  .ant-select-selector{border:none!important;border-radius:0;border-bottom:1px solid #339966; background: transparent}
  .fullscreen{ position: absolute; top: 0; left: 0; right: 0; bottom: 0 }
  .gradient-fullscreen{
    position: absolute; top: 0; left: 0; z-index:1; pointer-events: none; touch-action: none;
    max-width:100vw; max-height: 100vh; width:100vw; height: 100vh; 
    background: linear-gradient(180deg,#00000060 0%,#00000000 120px,#00000000 calc(100% - 120px), #00000060 100%), 
    linear-gradient(90deg, #00000060 0%, #00000000 120px, #00000000 calc(100% - 120px), #00000060 100%);
  }
  @media (max-width: 767px) {
    .gradient-fullscreen{
      position: absolute; bottom:0; top: 0; left: 0; right: 0; z-index:1; pointer-events: none; touch-action: none;
      background: linear-gradient(180deg,#00000060 0%,#00000000 40px,#00000000 calc(100% - 40px), #00000060 100%), 
      linear-gradient(90deg, #00000060 0%, #00000000 40px, #00000000 calc(100% - 40px), #00000060 100%);
    }
  }
  .no-touch{pointer-events: none; touch-action: none;}
  .zindex--1{z-index:-1}
  .zindex-10{z-index:10}

  .opacity-animation{ transition: opacity 0.5s ease-in-out}
  .hidden-delay{opacity:0;visibility:hidden;pointer-events: none; touch-action: none;transition-delay:1s; transition: opacity 0.25s ease-in-out}
  .opacity-05{ opacity: 0.9; pointer-events: none; touch-action: none; transition: all 0.5s ease-in; @media (max-width: 768px){ opacity: 0.96}}
  .preview-invisible{opacity:0; pointer-events: none; touch-action: none; transition: all 0.5s ease-in}

  .row-no-padding{margin-left: 0; margin-right: 0; [class*="col-"] { padding-left: 0 !important; padding-right: 0 !important}}
  .ant-modal-title{color:#FFF; font-family:Didot; font-size:24px;text-align:center}
  .ant-modal-content{min-width:250px;min-height:200px;padding:30px;background:#000000D9; color: #FFF; p,span{font-family:DIN2014;font-size:16px} }
  .ant-modal-header{border-bottom:none; background:transparent; color: #FFF}
  .ant-modal-body{text-align: left; color: #FFF; p,span{font-family:DIN2014;font-size:16px}}
  .ant-modal-mask{transition: all 1.5s; backdrop-filter: blur(3px)}
  .slick-slide.slick-active{img{transform:scale(0.8)}}
  .slick-slide.slick-active.slick-current{
    img{
      transform:scale(0.9);
      @media (max-width: 768px){
        transform:scale(1);
      }
    }
  }
  
  .ant-carousel{mix-blend-mode:multiply; margin-top:auto!important;margin-bottom:auto!important}
  #back-icon,#next-icon{svg{height:36px} :hover{svg{path{fill:#339966; }}} }
 
  .product-360{justify-content: center}
  .cloudimage-inner-box { 
    object-fit:cover!important;
    width:auto!important;
    height:100%!important;
    position: relative;
      > div{
        display:block;
        position: fixed !important;
        right: 45px !important;
        top:   165px !important;
        left: unset !important;
        bottom: unset !important;
        width: 100vw;
      }
      @media (max-width: 767px) { > div{ right: 15px !important} }
  }

  .cloudimage-360{canvas{display:block;object-fit:cover!important;width:auto!important;height:400px}} 

  .canvas-100{
    .cloudimage-inner-box{   
      canvas{
        padding-left:25px; 
        padding-right:25px; 
        width:100%!important
      }
    }
  }

  .cloudimage-360{margin:auto;mix-blend-mode:darken;.cloudimage-inner-box,canvas{min-height:100px}}
  .text{
    &.hr-white{border:1px solid #ffffff80}
    &.uppercase{text-transform: uppercase}
    &.capitalize{text-transform: capitalize}
    &.didot{font-family:Didot}
    &.cataraman{ font-family: 'Catamaran', sans-serif !important;}
    &.din{font-family:DIN2014}
    &.hr{hr{margin:4px 0}}
    &.black{color:#2a2a2a}
    &.dark{color:#2a2a2ad6}
    &.dark-gray{color:#7F7F7F}
    &.gray{color:#77777780}
    &.white{color:#FFFFFF}
    &.size-44{font-size:44px} 
    &.size-40{font-size:40px} 
    &.size-34{font-size:34px} 
    &.size-32{font-size:32px} 
    &.size-30{font-size:30px} 
    &.size-24{font-size:24px}
    &.size-20{font-size:20px}
    &.size-18{font-size:18px}
    &.size-16{font-size:16px}
    &.size-14{font-size:14px}
    &.size-13{font-size:13px}
    &.size-12{font-size:12px}
  }
  #root{ 
    
  }
  svg {
    [id^=visit-] {
      cursor: pointer;
    }

    .map-product-hover {
      cursor: pointer;
    }
  }
  .product-arrow{ color:#CCCCCC; height:32px; width:32px; font-size:32px }
  .panorama-arrow{ color:#FFFFFF; height:14px; width:14px; font-size:14px;line-height: 0.8 }
  .panorama-button{
    width:120px;
    height:40px;
    .label{
      font-size: 10px;
    }
    
    &.mobile{
      padding: 5px; width: 100px; overflow:hidden; height: 44px; font-size: 11px; word-break: break-word;
    }
  }

    .cloudimage-360 .cloudimage-360-prev, .cloudimage-360 .cloudimage-360-next {
      padding: 8px;
      background: rgb(244, 244, 244);
      border: none;
      border-radius: 4px;

    }

    .cloudimage-360 .cloudimage-360-prev:focus, .cloudimage-360 .cloudimage-360-next:focus {
      outline: none;
    }

    .cloudimage-360 .cloudimage-360-prev {
      display: none;
      position: absolute;
      z-index: 100;
      top: calc(95%);
      left: 20px;
      @media (max-width: 768px){
        top: calc(85%);
      }
    }

    .cloudimage-360 .cloudimage-360-next {
      display: none;
      position: absolute;
      z-index: 100;
      top: calc(95%);
      right: 20px;
      @media (max-width: 768px){
        top: calc(85%);
      }
    }

    .cloudimage-360 .cloudimage-360-prev:before, .cloudimage-360 .cloudimage-360-next:before {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      background: 50% 50% / cover no-repeat;
    }

    .cloudimage-360 .cloudimage-360-prev:before {
      background-image: url('https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/assets/img/arrow-left.svg');
    }

    .cloudimage-360 .cloudimage-360-next:before {
      background-image: url('https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/assets/img/arrow-right.svg');
    }

    .cloudimage-360 .cloudimage-360-prev.not-active, .cloudimage-360 .cloudimage-360-next.not-active {
      opacity: 0.4;
      cursor: default;
    }

    .info-balloon{
      .title, .title p{
        color:#FFF; font-family:Didot; font-size:24px;text-align:center
      }
      .description {
        p, span { text-align: left; color: #FFF; font-family:DIN2014;font-size:16px;}
      }
    }
    
    .hotspot-icon{
      svg {
        opacity: 0.5;
        &:hover{
          transform: scale(1.2);
          opacity: 1;
        }
      }
    }
    .mh-200px {max-height: 200px; height: 200px}
    .map-product-hover{
      rect,path{ stroke: #339966!important; stroke-width:1.5px!important; fill: #33996635!important; fill-opacity:1!important; transition: all 0.25s ease-in-out}
      .transparent{ fill:transparent!important;fill-opacity:0!important;}
    }
    .visible-area{pointer-events:none; user-select: none;}
    .not-interact{pointer-events:none; user-select: none;}
}
.inner-circle { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); background: #339966; width: 10px; height: 10px; border-radius: 50% }
.compass-radar { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%) }
.compass-radar::after { content: ''; position: absolute; left: 0; right: 0; top: 0; bottom: 0; transform: scale(0); border-radius: 50%; animation-duration: 4s; animation-name: pulse; animation-iteration-count: infinite; opacity: 0;}
.compass-radar:nth-child(1) {width:40px;height:40px}
.compass-radar:nth-child(2) {width:80px;height:80px}
.compass-radar:nth-child(1):after {border: solid 2px #33996680; background: #33996650}
.compass-radar:nth-child(2):after {border: solid 2px #33996630; background: #33996605}
@keyframes pulse { 0% {transform:scale(0); opacity: 0 } 50% {  transform: scale(1); opacity: 1 } 100% { transform: scale(1); opacity: 0 }}
.container-map { padding: 0 24px; @media (max-width: 768px) { padding: 18px;}; overflow: hidden; max-inline-size: fit-content; max-width: fit-content }
.map-zone{
  padding: 32px; 
  z-index:2; 
  top: 0; 
  bottom: 0; 
  right: 0; 
  left: 0; 
  width: auto; 
  height: 100%; 
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 30px;
  @media (max-width: 768px) {padding: 0px !important}
}
`
ReactDOM.render(<Provider store={store}><GlobalStyle /><App /></Provider>, document.getElementById('root'))
serviceWorker.unregister()
