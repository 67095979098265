import React from 'react'
import { ViewProduct, VisitPoint } from '../act-1'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap = ({
  height,
  width,
  setActiveProduct,
  hoverProduct,
  history = {},
  setPointInfo
}) => {
  const [hovered, setHover] = React.useState('')
  const findProduct = (id) => catalog.find((x) => x.id === id)
  const clickIn = (id) => {
    // hoverProduct && setHover()
    // if (history?.push && id?.startsWith('visit-')) { history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    hoverProduct && setHover(id)
    setActiveProduct && setActiveProduct(findProduct(id))
  }
  const hoverIn = (id) => {
    if (hovered === id) return
    hoverProduct && setHover(id)
    setActiveProduct && setActiveProduct(findProduct(id))
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 227 193'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='zone-4'>
        <path
          id='Path 7254'
          d='M213.5 188.68L4.52734 188.68L4.52734 60.6797V3.50098H43.2148'
          stroke='#545454'
          strokeWidth='7'
        />

        <ViewProduct
          hovered={hovered}
          hoverIn={hoverIn}
          name='act4-panton-side-table'
        >
          <path
            d='M6.30792 94.7218C6.30792 98.3084 9.21516 101.216 12.8014 101.216C16.3877 101.216 19.2949 98.3084 19.2949 94.7218V92.6388C19.2949 89.0523 16.3877 86.1448 12.8014 86.1448C9.21516 86.1448 6.30792 89.0523 6.30792 92.6388L6.30792 94.7218Z'
            stroke='#545454'
          />
        </ViewProduct>
        <ViewProduct hovered={hovered} hoverIn={hoverIn} name='act4-helen-bed'>
          <path
            d='M26.5019 134.633V110.039H13.5149L13.5149 134.633H26.5019Z'
            stroke='#545454'
          />
          <path
            d='M27.0969 160.525V135.931H13.5149L13.5149 160.525H27.0969Z'
            stroke='#545454'
          />
          <path
            d='M74.7139 111.861V160.191C74.7139 161.385 74.2398 162.53 73.3959 163.373C72.5519 164.217 71.4074 164.691 70.2139 164.691H14.0139C13.8813 164.691 13.7541 164.639 13.6603 164.545C13.5666 164.451 13.5139 164.324 13.5139 164.191V107.861C13.5139 107.729 13.5666 107.602 13.6603 107.508C13.7541 107.414 13.8813 107.361 14.0139 107.361H70.2139C71.4074 107.361 72.5519 107.836 73.3959 108.68C74.2398 109.523 74.7139 110.668 74.7139 111.861V111.861Z'
            stroke='#545454'
          />
          <path
            d='M76.7969 161.679V109.48C76.7969 106.995 74.7822 104.98 72.2969 104.98H10.8729C8.38759 104.98 6.37287 106.995 6.37287 109.48V161.679C6.37287 164.165 8.38759 166.179 10.8729 166.179H72.2969C74.7822 166.179 76.7969 164.165 76.7969 161.679Z'
            stroke='#545454'
          />
        </ViewProduct>
        <ViewProduct
          hovered={hovered}
          hoverIn={hoverIn}
          name='act4-megan-armchair'
        >
          <path
            d='M88.3929 82.0685L83.4414 87.5678C82.6428 88.4547 81.5246 88.9881 80.3327 89.0505C79.1409 89.113 77.9731 88.6994 77.0861 87.9008L67.4119 79.1901C66.525 78.3915 65.9916 77.2733 65.9291 76.0815C65.8667 74.8896 66.2802 73.7218 67.0788 72.8349L72.0304 67.3356C73.984 65.1659 76.7193 63.8609 79.6349 63.7076C82.5505 63.5543 85.4076 64.5651 87.578 66.5179V66.5179C89.7482 68.472 91.0533 71.2081 91.2061 74.1244C91.3589 77.0407 90.347 79.8983 88.3929 82.0685V82.0685Z'
            stroke='#545454'
          />
        </ViewProduct>
        <ViewProduct
          hovered={hovered}
          hoverIn={hoverIn}
          name='act4-cleo-mirror'
        >
          <path
            transform='translate(-20 0)'
            d='M86.1699 174.852C83.6846 174.852 81.6699 176.866 81.6699 179.352C81.6699 181.837 83.6846 183.852 86.1699 183.852C88.6552 183.852 90.6699 181.837 90.6699 179.352C90.6699 176.866 88.6552 174.852 86.1699 174.852Z'
            stroke='#545454'
          />
        </ViewProduct>

        <VisitPoint
          hovered={hovered}
          clickIn={clickIn}
          hoverIn={hoverIn}
          url={28}
        >
          <path
            d='M39.2275 75.2617C36.7423 75.2617 34.7275 77.2764 34.7275 79.7617C34.7275 82.247 36.7423 84.2617 39.2275 84.2617C41.7128 84.2617 43.7275 82.247 43.7275 79.7617C43.7275 77.2764 41.7128 75.2617 39.2275 75.2617Z'
            fill='#339966'
          />
        </VisitPoint>
        <VisitPoint
          hovered={hovered}
          clickIn={clickIn}
          hoverIn={hoverIn}
          url={26}
        >
          <path
            d='M84.415 130.103C81.9298 130.103 79.915 132.117 79.915 134.603C79.915 137.088 81.9298 139.103 84.415 139.103C86.9003 139.103 88.915 137.088 88.915 134.603C88.915 132.117 86.9003 130.103 84.415 130.103Z'
            fill='#339966'
          />
        </VisitPoint>
        <VisitPoint
          hovered={hovered}
          clickIn={clickIn}
          hoverIn={hoverIn}
          url={27}
        >
          <path
            d='M86.1699 174.852C83.6846 174.852 81.6699 176.866 81.6699 179.352C81.6699 181.837 83.6846 183.852 86.1699 183.852C88.6552 183.852 90.6699 181.837 90.6699 179.352C90.6699 176.866 88.6552 174.852 86.1699 174.852Z'
            fill='#339966'
          />
        </VisitPoint>
      </g>
    </svg>
  )
}

export const ActMap4 = React.memo(ActMap)
