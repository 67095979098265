import React from 'react'
import PropTypes from 'prop-types'

const MenuInfo = React.memo(({ fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'>
    <g id='Group_411' data-name='Group 411' transform='translate(-1816 -170)'>
      <path d='M16,145h4v-8H16Z' transform='translate(1815.5 49.5)' fill={fill} />
      <path d='M16,134h4v-4H16Z' transform='translate(1815.5 49.5)' fill={fill} />
    </g>
  </svg>

))
MenuInfo.propTypes = { fill: PropTypes.string }
MenuInfo.defaultProps = { fill: '#fff' }
export { MenuInfo }
