import React from 'react'
import PropTypes from 'prop-types'
const borderStyle = { border: '2px solid transparent' }
const FavoriteIcon = React.memo(({ fill }) => (
  <svg style={borderStyle} xmlns='http://www.w3.org/2000/svg' width='18' height='15' viewBox='0 0 18 15'>
    <path fill={fill} d='M26.993,14.9C26.811,18.48,23.225,21.334,20,24c-1.7,1.4-2.795,1.4-4.5,0-3.2-2.624-6.358-5.506-6.5-9.212-.173-4.656,5.451-6.562,8.646-2.763.35.475.35.475.7,0C21.531,8.208,27.235,10.14,26.993,14.9Z' transform='translate(-9 -10)' />
  </svg>
))
FavoriteIcon.propTypes = { fill: PropTypes.string }
FavoriteIcon.defaultProps = { fill: '#FFF' }
export { FavoriteIcon }
