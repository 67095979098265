import React, { useRef, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { Canvas } from "@react-three/fiber";
import { connect } from "react-redux";

// Components
import CameraControls from "./controls/cameracontrol";
import Tour from "./tour";
import LeftMap from "./LeftMap";

// Utils
import { isMobile } from "../../utils";

// Redux
import { backupCameraPos } from "../../effects/actions";

const VirtualTour = ({
  sphereData,
  pointInfo,
  setPointInfo,
  hotSpotsVisible,
  setHotSpotsVisible,
  currentAct,
  cameraPos,
  backupCameraPos,
  rotate
}) => {
  const history = useHistory();

  const [cursor, setCursor] = useState("auto");

  const onCreatedCanvas = (c) => {
    const { gl } = c;
  };

  const onPointClick = (id) => {
    // console.log(id);
    if (id !== pointInfo.pointId) {
      setPointInfo({ prevPointId: pointInfo.pointId, pointId: id });
      setHotSpotsVisible(false);
    }
  };

  const removeSphere = () => {
    setPointInfo({ ...pointInfo, prevPointId: null });
  };

  const backupCamera = (pos) => {
    // console.log(pos);
    backupCameraPos(pos)
  };

  return (
    <div className="fullscreen">
      <div className="gradient-fullscreen" />
      <Canvas
        camera={{ position: cameraPos, near: 0.00001, far: 10000 }}
        colorManagement={true}
        pixelRatio={window.devicePixelRatio}
        style={{ cursor: cursor }}
        linear={true}
      >
        <Tour
          sphereData={sphereData}
          pointId={pointInfo.pointId}
          prevPointId={pointInfo.prevPointId}
          hotSpotsVisible={hotSpotsVisible}
          setHotSpotsVisible={setHotSpotsVisible}
          onPointClick={onPointClick}
          removeSphere={removeSphere}
          setCursor={setCursor}
          history={history}
          backupCamera={backupCamera}
        />
        <CameraControls
          rotateSpeed={!isMobile ? -0.3 : -0.4}
          dampingFactor={!isMobile ? 0.1 : 0.15}
          autoRotate={rotate}
          autoRotateSpeed={0.1}
        />
      </Canvas>
      <LeftMap currentAct={currentAct} setPointInfo={onPointClick} />
    </div>
  );
};

const mapStateToProps = ({ app }) => {
  const { cameraPos } = app;

  return { cameraPos };
};
export default connect(mapStateToProps, { backupCameraPos })(
  React.memo(VirtualTour)
);
