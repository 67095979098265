import React, { useMemo } from 'react'
import * as _ from 'lodash'
import * as THREE from 'three'

// Components
import HotSpot from './HotSpot'

const HotSpots = ({
  sphereData,
  pointId,
  goPointClick,
  visible,
  setCursor
}) => {
  const spots = useMemo(
    () => _.get(sphereData, [pointId, 'hotspots'], []),
    [pointId, sphereData]
  )

  const sphereRotation = useMemo(
    () => _.get(sphereData, [pointId, 'sphereRotation'], [0, 0, 0]),
    [pointId, sphereData]
  )

  return (
    <group rotation={_.map(sphereRotation, THREE.MathUtils.degToRad)} visible={visible}>
      {_.map(spots, (d, i) => (
        <HotSpot
          key={i}
          id={_.get(d, 'link', null)}
          position={_.get(d, 'position', [0, 0, 0])}
          size={_.get(d, 'size', [-50, 50])}
          rotation={_.get(d, 'rotation', [0, 0, 0])}
          link={_.get(d, 'link', 1)}
          goPointClick={goPointClick}
          setCursor={setCursor}
        />
      ))}
    </group>
  )
}

export default HotSpots
