import React from 'react'
import PropTypes from 'prop-types'

const LogoIcon = ({ fill, width, height }) => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' fill={fill} width={width} height={height} viewBox='0 0 291 97'>
    <title>logo_domkapanew</title>
    <g className='cls-1'>
      <path className='cls-2' d='M14.9321,86.2441l1.1441.8057a4.78,4.78,0,0,1-4.42,2.1065A5.3455,5.3455,0,0,1,7.6,87.6611,5.9067,5.9067,0,0,1,6.17,83.41V81.5381a5.7892,5.7892,0,0,1,1.4429-4.2119,5.3824,5.3824,0,0,1,4.043-1.4824,5.0788,5.0788,0,0,1,4.394,2.0019l-1.144.78a3.7633,3.7633,0,0,0-3.25-1.4561,3.9092,3.9092,0,0,0-2.99,1.1309A4.5407,4.5407,0,0,0,7.6,81.5381V83.41A4.76,4.76,0,0,0,8.627,86.66a3.8146,3.8146,0,0,0,3.0288,1.17A3.5959,3.5959,0,0,0,14.9321,86.2441Z' />
      <path className='cls-2' d='M26.3716,77.1963a2.7565,2.7565,0,0,0-2.314,1.0791,4.622,4.622,0,0,0-.832,2.8984V89h-1.43V76h1.43v1.7676a3.308,3.308,0,0,1,3.2759-1.9238,4.35,4.35,0,0,1,3.666,1.7939l-1.1958.8848A3.1339,3.1339,0,0,0,26.3716,77.1963Z' />
      <path className='cls-2' d='M44.5977,81.5381v1.3262h-8.606V83.41a4.8034,4.8034,0,0,0,1.0137,3.25,3.6762,3.6762,0,0,0,2.938,1.17,3.74,3.74,0,0,0,3.38-1.586l1.1441.8057a4.8818,4.8818,0,0,1-4.4463,2.1065,5.26,5.26,0,0,1-4.03-1.5215,5.9223,5.9223,0,0,1-1.43-4.2246V81.5381a6.2186,6.2186,0,0,1,1.2871-4.2246,4.6944,4.6944,0,0,1,3.731-1.47,4.6949,4.6949,0,0,1,3.7309,1.47A6.2181,6.2181,0,0,1,44.5977,81.5381Zm-8.606,0v.0264h7.1758v-.0264a5.044,5.044,0,0,0-.91-3.25A3.2521,3.2521,0,0,0,39.58,77.17a3.2521,3.2521,0,0,0-2.6782,1.1182A5.041,5.041,0,0,0,35.9917,81.5381Z' />
      <path className='cls-2' d='M50.6035,85.2822a3.3059,3.3059,0,0,1,1.1572-2.73,5.061,5.061,0,0,1,3.2627-.9356H58.82V80.0039a2.7808,2.7808,0,0,0-.7153-2.21,4.0961,4.0961,0,0,0-2.561-.5976,6.0784,6.0784,0,0,0-2.1451.2861,2.7036,2.7036,0,0,0-1.2607,1.1953l-1.0923-.7539a3.8412,3.8412,0,0,1,1.7031-1.6123,6.949,6.949,0,0,1,2.8213-.4677A5.4877,5.4877,0,0,1,59.17,76.78,4.091,4.091,0,0,1,60.25,80.0039V89H58.82V87.3877q-.936,1.77-3.6924,1.7686a5.026,5.026,0,0,1-3.3408-1.001A3.5529,3.5529,0,0,1,50.6035,85.2822ZM58.82,84.1641V82.89H55.0234q-2.99,0-2.99,2.418,0,2.4962,3.0937,2.4961a3.9793,3.9793,0,0,0,2.7691-.8447A3.6419,3.6419,0,0,0,58.82,84.1641Z' />
      <path className='cls-2' d='M67.8672,77.3262H66.2031V76h1.6641V73.14h1.43V76h3.1977v1.3262H69.2974v7.28a4.73,4.73,0,0,0,.416,2.3653q.4152.65,1.9238.65h.8579V89h-.91a3.7467,3.7467,0,0,1-2.8989-.9229,4.617,4.617,0,0,1-.8193-3.081Z' />
      <path className='cls-2' d='M88.07,81.5381v1.3262H79.4639V83.41a4.8039,4.8039,0,0,0,1.0136,3.25,3.6765,3.6765,0,0,0,2.938,1.17,3.7405,3.7405,0,0,0,3.38-1.586l1.144.8057a4.8816,4.8816,0,0,1-4.4462,2.1065,5.26,5.26,0,0,1-4.03-1.5215,5.9223,5.9223,0,0,1-1.43-4.2246V81.5381a6.2186,6.2186,0,0,1,1.2871-4.2246,5.4705,5.4705,0,0,1,7.4619,0A6.2186,6.2186,0,0,1,88.07,81.5381Zm-8.6059,0v.0264H86.64v-.0264a5.0446,5.0446,0,0,0-.91-3.25,3.7665,3.7665,0,0,0-5.3565,0A5.0416,5.0416,0,0,0,79.4639,81.5381Z' />
      <path className='cls-2' d='M112.7954,86.2441l1.1441.8057a4.78,4.78,0,0,1-4.42,2.1065,5.3454,5.3454,0,0,1-4.0556-1.4952,5.9067,5.9067,0,0,1-1.43-4.2509V81.5381a5.7892,5.7892,0,0,1,1.4429-4.2119,5.3821,5.3821,0,0,1,4.0429-1.4824,5.0788,5.0788,0,0,1,4.3941,2.0019l-1.1441.78a3.7631,3.7631,0,0,0-3.25-1.4561,3.909,3.909,0,0,0-2.99,1.1309,4.5407,4.5407,0,0,0-1.0659,3.2373V83.41a4.76,4.76,0,0,0,1.0268,3.25,3.8149,3.8149,0,0,0,3.0288,1.17A3.5962,3.5962,0,0,0,112.7954,86.2441Z' />
      <path className='cls-2' d='M129.5654,81.5381v1.8974A6.0431,6.0431,0,0,1,128.2,87.6611a5.8508,5.8508,0,0,1-7.813,0,6.0063,6.0063,0,0,1-1.3779-4.2256V81.5381a5.9557,5.9557,0,0,1,1.3779-4.2119,5.8718,5.8718,0,0,1,7.8,0A5.9575,5.9575,0,0,1,129.5654,81.5381Zm-1.43,1.8974V81.5381a4.7235,4.7235,0,0,0-1.001-3.25,4.2,4.2,0,0,0-5.7071,0,4.7636,4.7636,0,0,0-.9877,3.25v1.8974a4.8571,4.8571,0,0,0,.9751,3.2637,4.18,4.18,0,0,0,5.7329-.0137A4.8243,4.8243,0,0,0,128.1353,83.4355Z' />
      <path className='cls-2' d='M137.4429,89h-1.43V76h1.43v1.7676a3.4376,3.4376,0,0,1,3.4321-1.9238,3.87,3.87,0,0,1,3.874,2.5478,4.0134,4.0134,0,0,1,3.9522-2.5478,3.9394,3.9394,0,0,1,3.25,1.4169,6.3,6.3,0,0,1,1.1435,4.043V89h-1.43V81.33a4.94,4.94,0,0,0-.8193-3.0811,2.7462,2.7462,0,0,0-2.3008-1.0527,2.968,2.968,0,0,0-2.4053,1.04,4.1941,4.1941,0,0,0-.8706,2.7813V89h-1.43V81.33a4.9464,4.9464,0,0,0-.8189-3.0811,2.7477,2.7477,0,0,0-2.3012-1.0527,2.9293,2.9293,0,0,0-2.418,1.0654,4.5653,4.5653,0,0,0-.8579,2.9385Z' />
      <path className='cls-2' d='M159.0488,77.3262V76h1.6641v-.9355a4.6183,4.6183,0,0,1,.8193-3.0811,3.7074,3.7074,0,0,1,2.8731-.9238h.5976v1.3789h-.5713a2.1048,2.1048,0,0,0-1.873.6758,4.5924,4.5924,0,0,0-.416,2.3134V76h2.86v1.3262h-2.86V89h-1.43V77.3262Z' />
      <path className='cls-2' d='M180.707,81.5381v1.8974a6.0431,6.0431,0,0,1-1.3652,4.2256,5.8515,5.8515,0,0,1-7.8135,0,6.0063,6.0063,0,0,1-1.3779-4.2256V81.5381a5.9557,5.9557,0,0,1,1.3779-4.2119,5.8711,5.8711,0,0,1,7.8,0A5.9555,5.9555,0,0,1,180.707,81.5381Zm-1.4306,1.8974V81.5381a4.7235,4.7235,0,0,0-1.001-3.25,4.2,4.2,0,0,0-5.707,0,4.7657,4.7657,0,0,0-.9883,3.25v1.8974a4.8572,4.8572,0,0,0,.9756,3.2637,4.18,4.18,0,0,0,5.7334-.0137A4.8242,4.8242,0,0,0,179.2764,83.4355Z' />
      <path className='cls-2' d='M191.7305,77.1963a2.7568,2.7568,0,0,0-2.3145,1.0791,4.6252,4.6252,0,0,0-.832,2.8984V89h-1.43V76h1.43v1.7676a3.31,3.31,0,0,1,3.2764-1.9238,4.3511,4.3511,0,0,1,3.666,1.7939l-1.1963.8848A3.133,3.133,0,0,0,191.7305,77.1963Z' />
      <path className='cls-2' d='M201.1934,77.3262h-1.6641V76h1.6641V73.14h1.4306V76h3.1983v1.3262H202.624v7.28a4.7311,4.7311,0,0,0,.416,2.3653q.4146.65,1.9239.65h.8584V89h-.91a3.7447,3.7447,0,0,1-2.8984-.9229,4.6138,4.6138,0,0,1-.82-3.081Z' />
    </g><g className='cls-1'>
      <path className='cls-2' d='M35.0229,65H24.4639V61.7666A10.8208,10.8208,0,0,1,15.5547,65.99,14.1268,14.1268,0,0,1,5.1934,61.8984,16.2722,16.2722,0,0,1,.4419,50.1514,16.0715,16.0715,0,0,1,5.2593,38.4707a15.0809,15.0809,0,0,1,10.4272-3.96,11.6745,11.6745,0,0,1,8.7774,3.8272V16.8906h10.559ZM22.55,55.2324a7.0735,7.0735,0,0,0,2.2437-5.0156,7.7207,7.7207,0,0,0-1.98-4.751,6.457,6.457,0,0,0-4.8179-1.98,6.462,6.462,0,0,0-4.6856,1.9131,7.2451,7.2451,0,0,0-2.0459,5.0156,6.6658,6.6658,0,0,0,2.0459,4.62,6.5544,6.5544,0,0,0,4.6856,1.9794A7.2085,7.2085,0,0,0,22.55,55.2324Z' />
      <path className='cls-2' d='M71.2568,61.1064C67.3633,64.9336,62.81,65.99,58.0581,65.99c-4.29,0-9.0415-.8584-13.1328-4.8838a15.217,15.217,0,0,1,0-21.7119c2.4414-2.3759,6.731-4.8838,13.1328-4.8838a18.6323,18.6323,0,0,1,13.1987,4.8838,15.217,15.217,0,0,1,0,21.7119ZM53.3726,45.3994A6.7315,6.7315,0,0,0,58.124,56.8828a6.7109,6.7109,0,0,0,4.6856-1.98,6.51,6.51,0,0,0,2.0459-4.6865A7.1678,7.1678,0,0,0,62.81,45.3994a6.7752,6.7752,0,0,0-9.437,0Z' />
      <path className='cls-2' d='M81.1592,35.501H91.7183v3.5635a11.331,11.331,0,0,1,8.8432-4.0918,12.0022,12.0022,0,0,1,5.6753,1.32,9.2108,9.2108,0,0,1,4.0918,4.2236,10.359,10.359,0,0,1,4.0254-4.0918,11.959,11.959,0,0,1,6.1377-1.4521c2.1777,0,5.7412.33,8.1831,2.706,2.64,2.5733,2.772,6.0713,2.772,8.1172V65H120.8877V50.415c0-3.1015-.33-5.2129-1.5181-6.3349a3.8494,3.8494,0,0,0-2.7719-.99,4.2751,4.2751,0,0,0-3.1016,1.1875c-1.7817,1.7168-1.9136,4.1582-1.9136,5.61V65H101.0234V50.415c0-2.8379-.1982-5.081-1.3862-6.3349A4.0927,4.0927,0,0,0,96.47,42.8916a3.7162,3.7162,0,0,0-2.9038,1.0566c-1.8476,1.7813-1.8476,4.6192-1.8476,6.4668V65H81.1592Z' />
      <path className='cls-2' d='M148.6094,16.8906V46.126l9.9648-10.625h14.32L158.9707,49.0957,174.0176,65H159.3662L148.6094,52.9229V65H138.05V16.8906Z' />
      <path className='cls-2' d='M197.3828,35.501h10.5586V65H197.3828V61.7666A11.02,11.02,0,0,1,188.209,65.99a14.1266,14.1266,0,0,1-10.625-4.6855,14.8809,14.8809,0,0,1-4.2227-10.8233,16.3245,16.3245,0,0,1,4.5528-11.8134,15.1856,15.1856,0,0,1,10.625-4.1573,11.6748,11.6748,0,0,1,8.8437,3.8272Zm-11.0879,9.8984a6.9052,6.9052,0,0,0-2.1113,4.8174,6.8071,6.8071,0,0,0,2.0459,4.8184,7.4157,7.4157,0,0,0,4.7519,1.9794,6.7663,6.7663,0,0,0,4.751-2.0458,6.4058,6.4058,0,0,0,1.98-4.752,6.5266,6.5266,0,0,0-2.0459-4.8174,6.6949,6.6949,0,0,0-9.3721,0Z' />
      <path className='cls-2' d='M225.0381,80.1123h-10.56V35.501h10.56v3.2334a11.0265,11.0265,0,0,1,9.041-4.2237A14.6676,14.6676,0,0,1,244.5723,38.8a15.9705,15.9705,0,0,1,4.4873,11.417,15.9688,15.9688,0,0,1-4.751,11.6816A14.1745,14.1745,0,0,1,234.2109,65.99a12.5561,12.5561,0,0,1-9.1728-3.8281Zm1.7158-34.6465A7.192,7.192,0,0,0,224.708,50.35a7.1193,7.1193,0,0,0,2.0459,4.6856,6.7469,6.7469,0,0,0,4.752,1.9794,6.3725,6.3725,0,0,0,4.6855-1.9794,6.9862,6.9862,0,0,0,2.0449-4.6856,7.1954,7.1954,0,0,0-2.0449-4.8838,6.3726,6.3726,0,0,0-4.6855-1.98A6.747,6.747,0,0,0,226.7539,45.4658Z' />
      <path className='cls-2' d='M277.2422,35.501h10.56V65h-10.56V61.7666a11.019,11.019,0,0,1-9.1729,4.2236,14.1254,14.1254,0,0,1-10.625-4.6855,14.8816,14.8816,0,0,1-4.2236-10.8233,16.3206,16.3206,0,0,1,4.5537-11.8134,15.1839,15.1839,0,0,1,10.625-4.1573,11.6741,11.6741,0,0,1,8.8428,3.8272Zm-11.0869,9.8984a6.9019,6.9019,0,0,0-2.1123,4.8174,6.8039,6.8039,0,0,0,2.0468,4.8184,7.4111,7.4111,0,0,0,4.751,1.9794,6.7689,6.7689,0,0,0,4.752-2.0458,6.4059,6.4059,0,0,0,1.9795-4.752,6.5248,6.5248,0,0,0-2.045-4.8174,6.6947,6.6947,0,0,0-9.372,0Z' />
        </g>
  </svg>
)

LogoIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

LogoIcon.defaultProps = {
  fill: '#FFF',
  width: 140,
  height: 50
}

export { LogoIcon }
