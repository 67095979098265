import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useSpring, animated } from "@react-spring/three" 

import { Chatspot } from '../../../assets'

const ChatSpot = ({ position, chat, setCursor }) => {
  const [hovered, setHover] = useState(false)
  const [texture, setTexture] = useState(null)

  useEffect(() => {
    const loader = new THREE.TextureLoader()
    loader.load(Chatspot, (t) => {
      setTexture(t)
    })
  }, [])

  // const pScale = hovered ? [-40, 40] : [-30, 30]

  const { pScale } = useSpring({
    pScale:
      hovered ? [-40, 40] : [-30, 30],
    config: { duration: 200 },
  });

  const onClickChat = () => {
    chat && window.$_Tawk && window.$_Tawk.toggle()
  }

  const onOverHotspot = () => {
    setCursor('pointer')
    setHover(true)
  }

  const onOutHotspot = () => {
    setCursor('auto')
    setHover(false)
  }

  return (
    <animated.sprite
      position={position}
      scale={pScale}
      renderOrder={2}
      onPointerOver={onOverHotspot}
      onPointerOut={onOutHotspot}
      onPointerDown={onClickChat}
    >
      <spriteMaterial attach='material' map={texture} transparent />
    </animated.sprite>
  )
}

ChatSpot.propTypes = {}

export default ChatSpot
