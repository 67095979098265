import { REHYDRATE } from 'redux-persist'
import { FavoritesActionType } from '../../types/favorites'

const defaultState = {
  loading: false,
  products: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const reduxState = { ...defaultState, ...action.payload?.favorites }
      return {
        ...state,
        products: reduxState.products
      }

    case FavoritesActionType.ADD:
      return {
        loading: false,
        products: [
          ...state.products.filter(item => item.id !== action.payload.id),
          action.payload
        ]
      }

    case FavoritesActionType.REMOVE:
      return {
        loading: false,
        products: state.products.filter(item => item.id !== action.payload.id)
      }

    default:
      return state
  }
}
