import {
  all, call, fork, put, takeEvery
} from 'redux-saga/effects'

import {
  postVisitorAPIFailure,
  postVisitorAPISuccess
} from './../../actions'
import {
  VisitorActionType
} from './../../types'
import { API } from './../../../utils/api'

const postVisitorRequest = async (user) => API.post('visitors', { ...user, company: 'domkapa' })
function * postVisitorAPI ({ payload }) {
  try {
    const request = yield call(postVisitorRequest, payload)
    yield put(postVisitorAPISuccess(request))
  } catch (error) {
    yield put(postVisitorAPIFailure(error))
  }
}

export function * postVisitor () {
  yield takeEvery(VisitorActionType.POST_VISITOR_API, postVisitorAPI)
}

export default function * rootSaga () {
  yield all([
    fork(postVisitor)
  ])
}
