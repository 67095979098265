import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'

// Images
// import { Walkspot } from "../../../assets";

const HotSpot = ({
  id,
  position,
  size,
  rotation,
  goPointClick,
  setCursor,
  link
}) => {
  console.log("HotSpot")
  const [texture, setTexture] = useState(null)
  const [hovered, setHover] = useState(false)

  const pScale = hovered ? [size[0] + 5, size[0] + 5, size[1] + 5] : [size[0],size[0], size[1]];

  useEffect(() => {
    // const loader = new THREE.TextureLoader();
    // loader.load(Walkspot, (t) => {
    //   setTexture(t);
    // });
  }, [])

  return (
    <group
      onPointerDown={() => {
        goPointClick(link)
      }}
      onPointerOver={() => {
        setCursor('pointer');
        setHover(true);
      }}
      onPointerOut={() => {
        setCursor('auto');
        setHover(false);
      }}
      position={position}
      rotation={rotation}
      scale={pScale}
    >
      <mesh renderOrder={2}>
        <ringBufferGeometry args={[0.5, 0.47, 32]} />
        <meshBasicMaterial
          color={0xffffff}
          side={THREE.DoubleSide}
          transparent
          opacity={0.9}
        />
      </mesh>
      <mesh renderOrder={2}>
        <ringBufferGeometry args={[0.45, 0.35, 32]} />
        <meshBasicMaterial
          color={0xffffff}
          side={THREE.DoubleSide}
          transparent
          opacity={0.9}
        />
      </mesh>
      <mesh renderOrder={2}>
        <circleBufferGeometry args={[0.35, 4]} />
        <meshBasicMaterial
          color={0xffffff}
          side={THREE.DoubleSide}
          transparent
          opacity={0.1}
        />
      </mesh>
    </group>
  )
}

HotSpot.propTypes = {}

export default HotSpot
