/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { postVisitorAPI } from './../../effects/actions'
import { store } from './../../effects/store'
import { useHistory } from 'react-router'
import { LogoLoadingIcon } from '../../components'
import { isMobile } from '../../utils'

const countries = require('./countries.json')
const positions = ['architect', 'interiordesigner', 'dealer', 'consumer', 'press']

const LoadingPage = ({ className = '', forceLogoOnly, redirect = true, redirectPath = '/vr' }) => {
  const history = useHistory()

  const redirectEffect = () => {
    redirect && setTimeout(() => {
      history.replace(redirectPath)

      store.dispatch(postVisitorAPI({
        name: 'Anonimo',
        mail: 'anonimo@anonimo.com',
        position: positions[0],
        country: countries[0].value
      }))
    }, 1000)
  }
  React.useEffect(redirectEffect, [])

  return (
    <div className={`background-dark absolute vh-100 z-index-20 no-touch ${className}`} style={{ width: '100%' }}>
      <div className='absolute-center'>
        <LogoLoadingIcon logoOnly={forceLogoOnly ?? isMobile()} />
      </div>
    </div>
  )
}

export default React.memo(LoadingPage)
