import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { LogoLoadingIcon } from '../../components'
import { isMobile } from '../../utils'
import { updateToken } from './../../effects/actions'

const TourPage = ({ token, updateToken }) => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (token === null) {
      const search = location.search.substring(1)
      let json = { token: null }
      let params = json
      if (search !== '') {
        json = '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
        params = JSON.parse(json)
      }
      if (params && params.token) {
        updateToken('token', params.token)
        history.replace('/vr/lobby/1')
      } else {
        history.replace('/welcome')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <div className='background-dark absolute vh-100 z-index-20 no-touch'>
      <div className='absolute-center'>
        <LogoLoadingIcon logoOnly={isMobile()} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ visitorAPI }) => {
  const { token } = visitorAPI

  return {
    token
  }
}

export default connect(mapStateToProps, {
  updateToken
})(React.memo(TourPage))
