import React from 'react'

const HotspotInfoIcon = ({ fill = 'rgba(255,255,255,0.8)' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
      <g id='Group_538' data-name='Group 538' transform='translate(-1403 -655)'>
        <g id='Rectangle_834' data-name='Rectangle 834' transform='translate(1403 655)' className='background-info-icon' fill={fill} stroke='#8c8c8c' strokeWidth='1'>
          <rect width='30' height='30' rx='15' stroke='none' />
          <rect x='0.5' y='0.5' width='29' height='29' rx='14.5' fill='none' />
        </g>
      </g>
    </svg>
  )
}

export { HotspotInfoIcon }
