import { lazy } from 'react'
import LoadingPage from './loading'
import TourPage from './tour'
export { LoadingPage, TourPage }
export const FormWelcomePage = lazy(() => import('./form-welcome'))
export const ProductPage = lazy(() => import('./product'))
export const VRPage = lazy(() => import('./vr'))
export const NavigationPage = lazy(() => import('./navigation'))
export const AnimationPage = lazy(() => import('./animation'))
export const AllProductsPage = lazy(() => import('./all-products'))
export const ShowRoomPage = lazy(() => import('./showrooms'))
