import {
  all, call, fork, put, takeEvery, select
} from 'redux-saga/effects'

import {
  wishlistUpdateFailure,
  wishlistUpdateSuccess
} from './../../actions'
import {
  FavoritesActionType
} from './../../types'

import { API } from './../../../utils/api'

const wishlistSelector = ({ favorites, visitorAPI }) => {
  return {
    username: (visitorAPI && visitorAPI.visitor && visitorAPI.visitor.email) || 'anonymous',
    wishlist: {
      products: favorites,
      company: 'domkapa'
    }
  }
}

const updateWishlistRequest = async (userId, wishlist) => API.put(`wishlist/${userId}`, wishlist)
function * updateWishlist () {
  try {
    const data = yield select(wishlistSelector)
    const request = yield call(updateWishlistRequest, data.username, data.wishlist)

    yield put(wishlistUpdateSuccess(request))
  } catch (error) {
    yield put(wishlistUpdateFailure(error))
  }
}

export function * syncWishlist () {
  yield takeEvery(FavoritesActionType.SYNC_LIST, updateWishlist)
}

export default function * rootSaga () {
  yield all([
    fork(syncWishlist)
  ])
}
