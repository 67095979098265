import React from 'react'

const CloseMenuIcon = ({ stroke = '#111', fill = 'rgba(0,0,0,0.07)' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'>
      <g id='Group_450' data-name='Group 450' transform='translate(0.5 0.5)'>
        <rect id='Rectangle_265' data-name='Rectangle 265' width='34' height='34' rx='17' fill={fill} stroke={stroke} strokeWidth='1' />
        <path id='Path_241' data-name='Path 241' d='M5718.845,1317.618l15,15' transform='translate(-5709.344 -1308.118)' fill='none' stroke={stroke} strokeWidth='2' />
        <path id='Path_242' data-name='Path 242' d='M5733.846,1317.618l-7.5,7.5-7.5,7.5' transform='translate(-5709.346 -1308.118)' fill='none' stroke={stroke} strokeWidth='2' />
      </g>
    </svg>
  )
}

CloseMenuIcon.propTypes = {
}

CloseMenuIcon.defaultProps = {

}

export { CloseMenuIcon }
