import React, { useState } from 'react'
import { Drawer, Input, Checkbox, Button, Select } from 'antd'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isMobile } from '../../utils'
import Product from './product'
import { NoResults } from './no-results'
import { CloseMenuIcon } from '../icons'
import { updateVisitorInput, postVisitorAPI } from './../../effects/actions'
const countries = require('./countries.json')

const StyledDrawer = styled(Drawer)`
  overflow-y: scroll;
  select,textarea,input,p, span{font-family:DIN2014; font-size:14px;}
  .error{
    color: #c22d30;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
  }
  .ant-checkbox-wrapper{
    color: #CCCCCC;
    font-family:DIN2014 !important;
  }
  .ant-drawer-content {
    background-color: #F4F4F4;
  }

  .ant-drawer-body {
    margin-top: 100px;

    .icon{
      &:hover{
        cursor:pointer;
      }
    }
    
  }

  .ant-drawer-close {
    outline: none;
    opacity: 0.9;
    margin-left: 15px;

    svg {
      height: 30px;
      width: 30px;
    }

    &:hover{
      opacity:1;
      cursor: pointer;
      transition:0.3s all;
      svg{
        rect{
          fill: #e3e3e3 !important;
        }
        path{
          stroke: #121212 !important;
          cursor: pointer;
        }
        
      }
    }
  }
  
`

const SecondaryButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 8px;
  height: 40px;
  background: transparent;
  border: 1px solid #339966;
  font-size:14px;
  display: inline-flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 100% !important;
  font-family:Radnika-Regular;
  color: #339966;
  text-transform: uppercase;
  :focus,:hover{
    outline:none;
    border:1px solid #339966;
    color:#339966!important
  }
`

const H1Styled = styled.h1`
  color: #CCCCCC;
  font-family: 'Catamaran', sans-serif !important;
  margin-bottom: 8px;
`

const renderFavorireProduct = (favorite, i) => {
  return (
    <Product
      key={i}
      id={favorite.id}
      name={favorite.fullname}
      image={`${favorite.id}_2.png`}
      description={favorite.product}
      dimensions={favorite.dimensions}
    />
  )
}

const FavoritesComponent = props => {
  const [error, setError] = useState(false)
  const onChange = event => {
    const { name, value } = event.target
    props.updateVisitorInput(name, value)
  }

  const onSubscribe = () => {
    const inputs = props.visitorInput
    if (inputs.name === '' || inputs.email === '' || inputs.position === '' || inputs.country === '' || inputs.term === false) {
      setError(true)
    } else {
      setError(false)
      props.postVisitorAPI(inputs)
    }
  }

  const searchFilter = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  const renderForm = (
    <div className='container p-8 '>
      <div className='row'>
        <div className='col-md-12'>
          <H1Styled>Save your wishlist</H1Styled>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-12'>
          <Input onChange={onChange} name='name' className='mb-3 din' width='100%' placeholder='Name*' value={props.visitorInput.name} />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-12'>
          <Input onChange={onChange} name='email' className='mb-3 din' width='100%' placeholder='E-mail*' value={props.visitorInput.email} />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-12 mb-3'>
          <Select
            onChange={(value) => {
              props.updateVisitorInput('position', value)
            }} placeholder='Profession' listItemHeight={30}
          >
            <Select.Option value='architect'>an Architect</Select.Option>
            <Select.Option value='interiordesigner'>an Interior Designer</Select.Option>
            <Select.Option value='dealer'>a Dealer</Select.Option>
            <Select.Option value='consumer'>a Consumer</Select.Option>
            <Select.Option value='press'>from Press</Select.Option>
          </Select>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-12 mb-3'>
          <Select
            showSearch filterOption={searchFilter} onChange={(value) => {
              props.updateVisitorInput('country', value)
            }} placeholder='Country'
          >
            {countries.map(country => (<Select.Option key={country.value} value={country.value}>{country.name}</Select.Option>))}
          </Select>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-12'>
          <Checkbox onChange={(e) => {
            e.preventDefault()
            props.updateVisitorInput('term', e.target.checked)
          }}
          >
            I agree to the terms and conditions.
          </Checkbox>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-12 error'>
          {error && 'All fields are required'}
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-md-12'>
          <SecondaryButton onClick={() => onSubscribe()}>Subscribe</SecondaryButton>
        </div>
      </div>
    </div>
  )
  return (
    <StyledDrawer
      closeIcon={<CloseMenuIcon fill='transparent' stroke='#ababab' />}
      placement='right'
      closable
      keyboard
      mask
      maskClosable
      visible={props.active}
      headerStyle={{ backgroundColor: '#dcdada' }}
      onClose={props.onClose}
      width={isMobile() ? '100%' : '40%'}
    >

      {
        props.visitor === null ? renderForm : (props.favorites.length > 0 ? props.favorites.map(renderFavorireProduct) : <NoResults />)
      }
    </StyledDrawer>
  )
}

export default connect(state => {
  return { favorites: state.favorites.products, visitor: state.visitorAPI.visitor, visitorInput: state.visitorAPI.visitorInput }
}, { updateVisitorInput, postVisitorAPI })(FavoritesComponent)
