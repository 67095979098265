import styled from 'styled-components'

export const StyledLi = styled.li`
  color: #545454;
  font-family:DIN2014;
  font-size: ${props => props.mobile ? 1.2 : 1.4}em;
  margin-bottom: 1em;
  svg{
    margin-right: 32px;
  }

  @media (max-width: 812px){
    svg{
      max-width: 30px;
    }
  }
`

export const StyledTitle = styled.h1`
  margin-left: 2em;
  margin-bottom: 24px;
  span{
    color: #545454;
    font-size: ${props => props.mobile ? 2.8 : 4.5}rem;
    letter-spacing: ${props => props.mobile ? 0.23 : 0.05}em;
    text-align: ${props => props.mobile ? 'center' : ''};
    font-family: 'Catamaran', sans-serif !important;
    text-transform: uppercase;
   
    &:after{
      display: block;
      content: "";
      height: 1px;
      width: 83%;
      background: #545454;
    }
  }
  @media (max-width: 812px){
    margin-left: 0;
    span{
      &:after{
        width: 100%;
      }
    }
  }
`

export const StyledUl = styled.ul`
  margin-left: ${props => props.mobile ? 0 : 1.1}em;
  list-style: none;
  
`
