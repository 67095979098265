import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import * as THREE from 'three'

// Components
import ProductInfoSpot from './ProductInfoSpot'
import VideoInfoSpot from './VideoInfoSpot'
import ChatSpot from './ChatSpot'

const InfoSpots = ({ sphereData, pointId, visible, setCursor, history, backupCamera }) => {
  const spots = useMemo(
    () => _.get(sphereData, [pointId, 'infospots'], []),
    [pointId, sphereData]
  )

  const sphereRotation = useMemo(
    () => _.get(sphereData, [pointId, 'sphereRotation'], [0, 0, 0]),
    [pointId, sphereData]
  )

  return (
    <group
      rotation={_.map(sphereRotation, THREE.MathUtils.degToRad)}
      visible={visible}
    >
      {_.map(spots, (d, i) => {
        const position = _.get(d, 'position', [0, 0, 0])
        const scale = _.get(d, 'size', [-50, 50])
        const type = _.get(d, 'type', '')
        const hover = _.get(d, 'hover', false)

        if (type === 'product-button') {
          return (
            <ProductInfoSpot
              key={i}
              position={position}
              to={_.get(d, 'to')}
              history={history}
              setCursor={setCursor}
              backupCamera={backupCamera}
            />
          )
        } else if (type === 'link') {
          return <></>
        } else if (type === 'video') {
          return (
            <VideoInfoSpot
              key={i}
              position={position}
              scale={scale}
              hover={hover}
              video={_.get(d, 'video')}
              setCursor={setCursor}
            />
          )
        } else if (type === 'chat') {
          return (
            <ChatSpot
              key={i}
              position={position}
              chat={_.get(d, 'chat', false)}
              text={_.get(d, 'text', '')}
              setCursor={setCursor}
            />
          )
        }
      })}
    </group>
  )
}

InfoSpots.propTypes = {}

export default InfoSpots
