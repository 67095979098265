import React from 'react'
import { Tooltip } from 'antd'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap = ({
  height,
  width,
  setActiveProduct,
  hoverProduct,
  history = {},
  setPointInfo
}) => {
  const [hovered, setHover] = React.useState('')
  const findProduct = (id) => catalog.find((x) => x.id === id)
  const clickIn = (id) => {
    // hoverProduct && setHover()
    // if (history?.push && id?.startsWith('visit-')) { history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    hoverProduct && setHover()
    setPointInfo(id)
  }

  const hoverIn = (id) => {
    if (hovered === id) return
    hoverProduct && setHover(id)
    setActiveProduct && setActiveProduct(findProduct(id))
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 220 158'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path className='no-touch' d='M220 0H0V158H220V0Z' fill='#E5E5E560' />
      <path
        className='no-touch'
        d='M185.154 153.617H35.2424'
        stroke='#545454'
        strokeWidth='7'
      />

      <ViewProduct
        hovered={hovered}
        hoverIn={hoverIn}
        name='act1-disruption-sofa'
      >
        <path
          d='M165.869 135.823L174.149 116.25L165.869 98.1885H121.161V135.82L165.869 135.823Z'
          stroke='#545454'
        />
        <path
          d='M56.5859 126.584C56.5859 126.584 56.6143 109.834 56.5859 106.652C56.5225 105.269 56.0655 103.933 55.2679 102.8C54.4705 101.667 53.366 100.786 52.0834 100.257C50.2717 99.725 48.3768 99.5343 46.4951 99.6957V135.823H83.8814C83.7331 134.11 83.3346 132.429 82.6986 130.83C82.0096 129.515 80.9642 128.42 79.6805 127.67C78.3967 126.919 76.9267 126.543 75.439 126.585C70.955 126.538 56.5859 126.584 56.5859 126.584Z'
          stroke='#545454'
        />
        <path
          d='M159.294 127.026C159.294 127.026 131.534 126.982 128.342 127.011C126.955 127.074 125.615 127.53 124.479 128.324C123.342 129.12 122.456 130.221 121.925 131.5C121.405 132.946 121.213 134.489 121.363 136.018L165.855 136.027L173.78 117.023C171.779 116.373 169.672 116.104 167.571 116.23C166.202 116.425 164.91 116.988 163.836 117.859C162.763 118.73 161.948 119.876 161.479 121.174C160.46 123.719 159.294 127.026 159.294 127.026Z'
          stroke='#545454'
        />
        <path
          d='M119.543 135.41H85.5235C85.237 135.41 84.9624 135.296 84.7599 135.095C84.5574 134.893 84.4436 134.618 84.4436 134.333C84.4431 133.406 84.626 132.488 84.9818 131.63C85.3376 130.774 85.8593 129.996 86.517 129.341C87.1748 128.685 87.9558 128.165 88.8153 127.81C89.6748 127.455 90.596 127.274 91.5262 127.274H113.545C114.475 127.274 115.395 127.456 116.254 127.811C117.113 128.166 117.894 128.686 118.552 129.342C119.208 129.997 119.73 130.776 120.086 131.632C120.441 132.489 120.624 133.406 120.623 134.333C120.623 134.618 120.509 134.893 120.307 135.095C120.105 135.296 119.829 135.41 119.543 135.41Z'
          stroke='#545454'
        />
        <path
          d='M84.0206 135.823V70.2954H46.5408V135.823H84.0206Z'
          stroke='#545454'
        />
        <path
          d='M121.332 135.823V98.1931H83.8521V135.823H121.332Z'
          stroke='#545454'
        />
      </ViewProduct>

      <ViewProduct
        hovered={hovered}
        hoverIn={hoverIn}
        name='act1-edison-armchair'
      >
        <path
          d='M173.996 49.6125L165.555 62.5665C165.205 63.1054 164.478 63.2591 163.938 62.909L163.894 62.881L163.847 62.8609C163.797 62.8398 158.823 60.7181 154.199 57.7233C149.566 54.7236 145.155 50.766 145.111 50.7264L145.076 50.695L145.037 50.6694C144.496 50.3193 144.343 49.5961 144.694 49.0571L153.134 36.1032C156.024 31.6681 161.995 30.4038 166.444 33.2847L171.17 36.3446C175.618 39.2255 176.886 45.1775 173.996 49.6125Z'
          stroke='#545454'
        />
      </ViewProduct>

      <ViewProduct
        hovered={hovered}
        hoverIn={hoverIn}
        name='act1-inside-coffee-table'
      >
        <path
          d='M110.316 77.0275H127.44C133.458 77.0275 138.337 72.1639 138.337 66.1644C138.337 60.1649 133.458 55.3013 127.44 55.3013H110.316C104.297 55.3013 99.4187 60.1649 99.4187 66.1644C99.4187 72.1639 104.297 77.0275 110.316 77.0275Z'
          stroke='#545454'
        />
      </ViewProduct>

      <ViewProduct
        hovered={hovered}
        hoverIn={hoverIn}
        name='act1-inside-tall-side-table'
      >
        <path
          transform='translate(-100 -60)'
          d='M132.561 172.518L138.869 169.178L139.107 162.044L133.031 158.246L126.722 161.586L126.484 168.72L132.561 172.518ZM132.515 173.802L125.35Z'
          stroke='#545454'
        />
      </ViewProduct>

      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={10}
      >
        <path
          d='M205.144 135.734C202.205 135.734 199.822 138.11 199.822 141.04C199.822 143.97 202.205 146.346 205.144 146.346C208.085 146.346 210.467 143.97 210.467 141.04C210.467 138.11 208.085 135.734 205.144 135.734Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={7}
      >
        <path
          d='M15.7402 140.908C12.8006 140.908 10.4176 143.284 10.4176 146.214C10.4176 149.144 12.8006 151.52 15.7402 151.52C18.6798 151.52 21.0627 149.144 21.0627 146.214C21.0627 143.284 18.6798 140.908 15.7402 140.908Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={6}
      >
        <path
          d='M25.5988 75.7275C22.6592 75.7275 20.2762 78.103 20.2762 81.0335C20.2762 83.9639 22.6592 86.3394 25.5988 86.3394C28.5384 86.3394 30.9214 83.9639 30.9214 81.0335C30.9214 78.103 28.5384 75.7275 25.5988 75.7275Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={9}
      >
        <path
          d='M197.879 89.6948C194.94 89.6948 192.557 92.0704 192.557 95.0008C192.557 97.9312 194.94 100.307 197.879 100.307C200.819 100.307 203.202 97.9312 203.202 95.0008C203.202 92.0704 200.819 89.6948 197.879 89.6948Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={5}
      >
        <path
          d='M69.1878 36.4129C66.2482 36.4129 63.8652 38.7885 63.8652 41.7188C63.8652 44.6493 66.2482 47.0248 69.1878 47.0248C72.1274 47.0248 74.5104 44.6493 74.5104 41.7188C74.5104 38.7885 72.1274 36.4129 69.1878 36.4129Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={4}
      >
        <path
          d='M112.777 14.1688C109.837 14.1688 107.454 16.5443 107.454 19.4748C107.454 22.4052 109.837 24.7807 112.777 24.7807C115.716 24.7807 118.099 22.4052 118.099 19.4748C118.099 16.5443 115.716 14.1688 112.777 14.1688Z'
          fill='#339966'
        />
      </VisitPoint>
      <VisitPoint
        hovered={hovered}
        clickIn={clickIn}
        hoverIn={hoverIn}
        url={8}
      >
        <path
          d='M154.29 68.4847C151.351 68.4847 148.967 70.8603 148.967 73.7907C148.967 76.7211 151.351 79.0967 154.29 79.0967C157.23 79.0967 159.612 76.7211 159.612 73.7907C159.612 70.8603 157.23 68.4847 154.29 68.4847Z'
          fill='#339966'
        />
      </VisitPoint>
    </svg>
  )
}

export const ActMap1 = React.memo(ActMap)

export const ViewProduct = React.memo(
  ({ name, hovered, children, hoverIn }) => {
    const hoverClass = (name) =>
      hovered === name ? `map-product-hover ${name}` : 'pointer'
    return (
      <g
        fill='white'
        fillOpacity='0.1'
        className={hoverClass(name)}
        onClick={() => hoverIn(name)}
        onPointerOver={() => hoverIn(name)}
        onPointerEnter={() => hoverIn(name)}
      >
        {children}
      </g>
    )
  }
)
export const VisitPoint = React.memo(
  ({ url, hovered, children, clickIn, hoverIn }) => {
    const isHovered = (name) => hovered === name
    return (
      <g
        className='pointer'
        onClick={() => clickIn(url)}
        onPointerOver={() => hoverIn(url)}
        onPointerEnter={() => hoverIn(url)}
      >
        <Tooltip trigger={['hover', 'click', isHovered(url)]} title='VISIT'>
          {children}
        </Tooltip>
      </g>
    )
  }
)
