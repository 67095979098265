import React from 'react'
import PropTypes from 'prop-types'

const MenuVolume = React.memo(({ fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'>
    <g id='Group_410' data-name='Group 410' transform='translate(-1816 -110)'>
      <path d='M14.332,74.875v6h1.91L22.665,85V70l-6.424,4.875Z' transform='translate(1814.5 49.5)' fill={fill} />
    </g>
  </svg>
))
MenuVolume.propTypes = { fill: PropTypes.string }
MenuVolume.defaultProps = { fill: '#fff' }
export { MenuVolume }
