import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { useThree } from '@react-three/fiber'
import * as _ from 'lodash'

// Components
import RoomSphere from '../room/RoomSphere'
import HotSpots from '../hotspots'
import InfoSpots from '../infospots'

const Tour = ({
  sphereData,
  pointId,
  prevPointId,
  onPointClick,
  removeSphere,
  hotSpotsVisible,
  setHotSpotsVisible,
  setCursor,
  history,
  backupCamera
}) => {
  const { camera } = useThree();

  const setCurrentPoint = (ev) => {}

  const onPointerDown = (e) => {
    // console.log("onPointerDown")
    // const p = _.get(data, pointId, null);
    let min = 100000;
    let selectedHotSpot = null;

    var vector = new THREE.Vector3();
    camera.getWorldDirection(vector);

    const angleRadian = (Math.PI * (camera.fov / 2)) / 180;

    const hotSpots = _.get(sphereData, [pointId, "hotspots"], []);

    // rotation={_.map(sphereRotation, THREE.MathUtils.degToRad)}
    const rotation = _.map(_.get(sphereData, [pointId, 'sphereRotation']), THREE.MathUtils.degToRad)
    hotSpots.forEach((h) => {
      const hp = _.get(h, "position", [0, 0, 0]);
      // console.log(hp)
      const hpp = new THREE.Vector3(hp[0], 0, hp[2]);
      hpp.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotation[1]);

      const angle = new THREE.Vector3(hpp.x, 0, hpp.z).angleTo(
        new THREE.Vector3(vector.x, 0, vector.z)
      );
      // console.log(angle, rotation, (angle - rotation[1]));
      if (angle < angleRadian) {
        const distance = hpp.distanceTo(new THREE.Vector3());
        if (distance < min) {
          min = distance;
          selectedHotSpot = h;
        }
      }
    });

    if (selectedHotSpot) onPointClick(selectedHotSpot.link);
  };

  const onNewPointRendered = () => {
    setHotSpotsVisible(true)
  }

  return (
    <>
      <RoomSphere
        data={sphereData}
        pointId={pointId}
        prevPointId={prevPointId}
        setCurrentPoint={setCurrentPoint}
        onPointerDown={onPointerDown}
        onAnimationEnd={onNewPointRendered}
        removeSphere={removeSphere}
      />
      <HotSpots
        sphereData={sphereData}
        pointId={pointId}
        goPointClick={onPointClick}
        visible={hotSpotsVisible}
        setCursor={setCursor}
      />
      <InfoSpots
        sphereData={sphereData}
        pointId={pointId}
        visible={hotSpotsVisible}
        setCursor={setCursor}
        history={history}
        backupCamera={backupCamera}
      />
    </>
  )
}

export default Tour
