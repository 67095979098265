import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { ProductCI360 } from './../'

const PrimaryButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 8px;
  height: 36px;
  background: #2a2a2a;
  border: 1px solid #2a2a2a;
  font-size:14px;
  font-family:Radnika-Regular;
  color: #E9E9E9;
  text-transform: uppercase;
  &:focus{
    outline:none;
    border:1px solid #339966;
    color:#fff;
    background:#2a2a2a
  }
  &:hover{
    outline:none;
    border:1px solid #339966;
    background:#339966;
    color:#fff
}`
const SecondaryButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 8px;
  height: 36px;
  background: #E9E9E9;
  border: 1px solid #7F7F7F;
  font-size:14px;
  font-family:Radnika-Regular;
  color: #7F7F7F;
  text-transform: uppercase;
  &:hover,  &:focus{
    outline:none;
    background:transparent;
    border:1px solid #339966;
    span {
      color:#339966;
    }
  }
`

const ProductViewComponent = ({ product, clickViewMore, clickViewAll }) => (
  <div className='mt-120 pt-1 mx-25 d-flex flex-fill flex-column text-center align-items-center'>

    <div className='d-flex flex-fill mw-667 w-100'>
      <ProductCI360 key={product?.id} product={product} />
    </div>

    <div className='text-left d-flex flex-column mw-667 w-100 mb-4'>
      <div className='d-flex justify-content-between align-items-end line-1'>
        <label className='text din black size-44 line-1 mt-2 mb-0 line-1'>{product?.brand}</label>
        <label className='text cataraman dark-gray size-16 mb-0 uppercase line-1'>{product?.product}</label>
      </div>
      <hr className='mt-05' />
      <span className='text din dark hr'>{product?.material}</span>
      <span className='text din dark mb-3'>{product?.dimensions} <span className='text dark-gray'>| {product?.dimensionslabel}</span></span>
      <span className='text din dark mb-3'>{product?.description}</span>

      <PrimaryButton block onClick={() => clickViewMore(product)}>VIEW MORE</PrimaryButton>
      <SecondaryButton onClick={() => clickViewAll(product)} block>VIEW ALL PRODUCTS</SecondaryButton>
    </div>
  </div>
)

export default React.memo(ProductViewComponent)
