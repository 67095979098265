import axios from 'axios'

export const API = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://domkapa-production.herokuapp.com/'
    : 'https://domkapa-production.herokuapp.com/',
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
