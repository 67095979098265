import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router'
import { AnimateOnChange, animations } from 'react-animation'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { CgBorderStyleDotted } from 'react-icons/cg'
import { IoMdArrowDropleft } from 'react-icons/io'
import 'react-animation/dist/keyframes.css'
import {
  LogoIcon,
  MenuIcon,
  CloseIcon,
  FavoriteIcon,
  Favorites,
  MenuInfo,
  MenuPlay,
  MenuShare,
  MenuVR,
  MenuZoom,
  MenuPause,
  MessageIcon
} from '..'
import ShowPlayerComponent from '../show-player'
import { store } from '../../effects/store'
import {
  StyledPlaylistDiv,
  ActionButton,
  StyledButtonDiv,
  MainLogo,
  Header,
  StyledSharedIcons,
  StyledDivSharedIcons,
  StyledArrow
} from './header.styled'
import Menu from './menu'
import { isMobile, mobileClass } from '../../utils'
import { AudioOnDark, AudioOn } from '../../assets'
import { updateMuted } from '../../effects/actions'
import { FaFacebookF, FaPinterest, FaTwitter } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

let changePosition
let welcomeScren

const HeaderComponent = ({
  white,
  menuIcon,
  closeIcon,
  favoriteIcon,
  favoriteIconFill,
  onFavoriteClick,
  showLogo,
  menuInfo,
  menuPlay,
  menuShare,
  menuVR,
  menuZoom,
  menuPause,
  leftLink,
  rightLink,
  onClose,
  onPlay,
  onPause,
  onVR,
  player,
  messageIcon,
  showPlaylist,
  isProduct,
  goPointer
}) => {
  const fill = white ? '#FFF' : '#777777'
  changePosition = !menuInfo
  welcomeScren = !!player
  const [active, setActive] = React.useState(false)
  const [wishlistBarOpen, setWishlistOpen] = React.useState(false)
  const [showSharedIcons, setShowSharedIcons] = React.useState(false)
  const [fullscreen, setFullscreen] = React.useState(false)
  const history = useHistory()
  const location = useLocation()
  const closeMenu = () => setActive(false)
  const openMenu = () => setActive(true)
  const toggleWishlist = () => setWishlistOpen(!wishlistBarOpen)

  const onFullscreen = () => {
    setFullscreen(!fullscreen)
    const elem = document.documentElement
    if (fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen()
      }
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
    }
  }

  const onOpenInfo = () => {
    history.replace('/navigation', {
      redirect: true,
      redirectPath: location.pathname,
      showProgress: false,
      byLocation: true
    })
  }

  return (
    <>
      <Header white={white} className={mobileClass('')} isProduct={isProduct}>
        <Menu active={active} closeMenu={closeMenu} />
        <Favorites
          active={wishlistBarOpen}
          onClose={toggleWishlist}
          position={favoriteIcon === 7}
        />
        {showSharedIcons && <ShowShared />}
        {showLogo && (
          <div className='d-flex justify-content-center h-100'>
            <MainLogo className={mobileClass('')}>
              <LogoIcon fill={fill} />
            </MainLogo>
          </div>
        )}

        <ActionButton
          left
          className={mobileClass('icon')}
          position={0}
          active={!!menuIcon}
          onClick={openMenu}
        >
          <MenuIcon fill={fill} />
        </ActionButton>
        <ActionButton
          right
          className={mobileClass('icon')}
          position={0}
          active={!!closeIcon}
          onClick={onClose}
        >
          <CloseIcon fill={fill} stroke={fill} />
        </ActionButton>
        <ActionButton
          right
          className={mobileClass('icon')}
          position={favoriteIcon}
          isFavorite
          active={!!favoriteIcon}
          onClick={() => {
            if (onFavoriteClick) onFavoriteClick()
            toggleWishlist()
          }}
        >
          <FavoriteIcon fill={favoriteIconFill || fill} />
        </ActionButton>
        <ActionButton
          right
          className={mobileClass('icon')}
          position={menuInfo}
          active={!!menuInfo}
          onClick={() => onOpenInfo()}
        >
          <MenuInfo fill={fill} />
        </ActionButton>
        <ActionButton
          right
          className={mobileClass('icon')}
          position={menuPlay}
          active={!!menuPlay}
          onClick={() => onPlay()}
        >
          <MenuPlay fill={fill} />
        </ActionButton>
        <ActionButton
          right
          className={mobileClass('icon')}
          position={menuPause}
          active={!!menuPause}
          onClick={() => onPause()}
        >
          <MenuPause fill={fill} />
        </ActionButton>
        <ActionButton
          right
          className={mobileClass('icon')}
          position={menuShare}
          active={!!menuShare}
        >
          <MenuShare fill={fill} />
        </ActionButton>
        <ActionButton
          right
          className={mobileClass('icon')}
          position={!isMobile() ? menuVR : menuVR - 1}
          active={!!menuVR}
          onClick={onVR}
        >
          <MenuVR fill={fill} />
        </ActionButton>
        {!isMobile() && (
          <ActionButton
            right
            className={mobileClass('icon')}
            position={menuZoom}
            active={!!menuZoom}
            onClick={onFullscreen}
          >
            <MenuZoom fill={fill} />
          </ActionButton>
        )}
        <ActionButton
          right
          className={mobileClass('icon')}
          position={messageIcon}
          active={!!messageIcon}
          onClick={() => setShowSharedIcons(!showSharedIcons)}
        >
          <MessageIcon fill={fill} />
        </ActionButton>

        {leftLink && (
          <PanoramaButton
            object={getPanoramaObject(leftLink)}
            left
            goPointer={goPointer}
          />
        )}
        {rightLink && (
          <PanoramaButton
            object={getPanoramaObject(rightLink)}
            right
            goPointer={goPointer}
          />
        )}
        {showPlaylist && <ShowPlaylist white={white} />}
      </Header>
    </>
  )
}

const PanoramaButton = React.memo(
  ({ position = 8.5, object = {}, left = false, right = false, goPointer }) => (
    <ActionButton
      active
      className={mobileClass(
        'panorama-button icon-link text din size-16 white uppercase'
      )}
      left={left}
      right={right}
      position={position}
      onClick={() => {
        goPointer(object.id)
      }}
    >
      {left && <LeftOutlined className='panorama-arrow mr-2' />}
      <span className='label'>{object.label}</span>
      {right && <RightOutlined className='panorama-arrow ml-2' />}
    </ActionButton>
  )
)

const ShowShared = React.memo(() => (
  <AnimateOnChange style={{ animation: animations.fadeIn }}>
    <StyledDivSharedIcons
      changePosition={!!changePosition}
      className='share-buttons'
    >
      <StyledSharedIcons
        onClick={() =>
          window.open(
            'http://pinterest.com/pin/create/button/?url=' +
              window.location.href,
            '_blank'
          )}
      >
        <FaPinterest
          className='react-icons-hover'
          color={!changePosition ? '#fff' : '#707070'}
        />
      </StyledSharedIcons>
      <StyledSharedIcons
        onClick={() =>
          window.open(
            'https://twitter.com/share?url=' + window.location.href,
            '_blank'
          )}
      >
        <FaTwitter color={!changePosition ? '#fff' : '#707070'} />
      </StyledSharedIcons>
      <StyledSharedIcons
        onClick={() =>
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' +
              encodeURIComponent(window.location.href),
            'facebook-share-dialog',
            'width=626,height=436'
          )}
      >
        <FaFacebookF color={!changePosition ? '#fff' : '#707070'} />
      </StyledSharedIcons>
      <StyledSharedIcons
        onClick={() => window.open('mailto:?subject=' + window.location.href)}
      >
        <FiMail color={!changePosition ? '#fff' : '#707070'} />
      </StyledSharedIcons>
      <StyledSharedIcons>
        <IoMdArrowDropleft
          color={!changePosition ? 'rgba(255, 255, 255, .5)' : '#707070'}
          className='arrow-left'
        />
      </StyledSharedIcons>
    </StyledDivSharedIcons>
  </AnimateOnChange>
))

const ShowPlaylist = React.memo(({ white }) => {
  const [showPlayList, setShowPlayList] = React.useState(false)
  const muted = store.getState().app.muted
  const act = store.getState().app.currentActSong
  const fill = white ? '#ffffff26' : '#b7b7b7'
  const fillHover = white ? '#FFF' : '#6b6b6b'
  const imgSrc = white ? AudioOn : AudioOnDark
  const imgStyle = { display: !muted ? 'none' : 'block' }
  const changePos = !(changePosition && !welcomeScren)
  const arrowColor = changePosition ? '#707070' : 'rgba(255, 255, 255, .5)'
  const togglePlayerVisibleMobile = () => {
    if (isMobile()) return
    setShowPlayList(!showPlayList)
  }

  const togglePlayerVisible = () => {
    if (!isMobile()) {
      store.dispatch(updateMuted())
      return
    }

    setShowPlayList(!showPlayList)
  }

  return (
    <div
      onMouseEnter={() => !showPlayList && togglePlayerVisibleMobile()}
      onMouseLeave={() => showPlayList && togglePlayerVisibleMobile()}
    >
      <StyledPlaylistDiv changePosition={changePos}>
        <StyledButtonDiv
          onClick={() => togglePlayerVisible()}
          fill={fill}
          fillHover={fillHover}
        >
          {!muted && <img src={imgSrc} alt='' />}
          <CgBorderStyleDotted style={imgStyle} color='#fff' size='1.5em' />
        </StyledButtonDiv>

        <ShowPlayerComponent show={showPlayList} act={act} />
        <StyledArrow show={showPlayList}>
          <IoMdArrowDropleft color={arrowColor} />
        </StyledArrow>
      </StyledPlaylistDiv>
    </div>
  )
})

HeaderComponent.propTypes = {
  white: PropTypes.bool,
  showLogo: PropTypes.bool,
  menuIcon: PropTypes.bool,
  closeIcon: PropTypes.bool,
  player: PropTypes.bool,
  leftLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rightLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  messageIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  favoriteIcon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  menuInfo: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  menuPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  menuPause: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  menuShare: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  menuVR: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  menuZoom: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  showPlaylist: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onClose: PropTypes.func,
  onVR: PropTypes.func,
  isProduct: PropTypes.bool
}
HeaderComponent.defaultProps = {
  white: false,
  showLogo: true,
  menuIcon: true,
  closeIcon: false,
  leftLink: false,
  rightLink: false,
  player: false,

  messageIcon: false,
  favoriteIcon: false,
  menuInfo: false,
  menuPlay: false,
  menuPause: false,
  menuShare: false,
  menuVR: false,
  menuZoom: false,

  isProduct: false,

  showPlaylist: true,

  onPlay: () => {},
  onPause: () => {},
  onClose: () => {},
  onVR: () => {}
}
export default React.memo(HeaderComponent)

const getPanoramaObject = (type) => {
  switch (type) {
    case 'act-0':
      return { id: 11, label: 'Production' }
    case 'act-1':
      return { id: 4, label: 'Area 1' }
    case 'act-2':
      return { id: 16, label: 'Area 2' }
    case 'act-3':
      return { id: 21, label: 'Area 3' }
    case 'act-4':
      return { id: 26, label: 'Area 4' }
    case 'act-5':
      return { id: 29, label: 'Area 5' }
    default:
      return {}
  }
}
