import { AppActionType } from "./../types";

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  currentActSong: null,
  muted: false,
  networkSpeed: 0,
  pointInfo: {
    prevPointId: null,
    pointId: 1,
  },
  cameraPos: [0, 0, 0.0001],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AppActionType.UPDATE_CURRENT_ACT:
      return {
        ...state,
      };

    case AppActionType.UPDATE_CURRENT_ACT_SONG:
      return {
        ...state,
        currentActSong: action.payload,
      };

    case AppActionType.UPDATE_MUTED:
      return {
        ...state,
        muted: action.payload === null ? !state.muted : action.payload,
      };
    case AppActionType.UPDATE_CURRENT_POINTER:
      return {
        ...state,
        pointInfo: action.payload,
      };
    case AppActionType.UPDATE_NETWORK_SPEED:
      return {
        ...state,
        networkSpeed: action.payload,
      };
    case AppActionType.BACKUP_CURRENT_CAMERA:
      return {
        ...state,
        cameraPos: action.payload,
      };
    default:
      return { ...state };
  }
};
