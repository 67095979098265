import React, { useRef, useState, useEffect } from "react";
import { BackSide, DoubleSide, Vector3 } from "three";
import PropTypes from "prop-types";
import * as THREE from "three";
import * as _ from "lodash";
import { gsap, Cubic } from "gsap";

import { useFrame, useThree } from "@react-three/fiber";

const Sphere = ({
  rotation,
  pointId,
  opacity = 0.3,
  visible,
  texture = null,
  setCurrentPoint,
  offset = [0, 0, 0],
  onAnimationEnd,
  onPointerDown,
  removeSphere,
  isAnimation,
}) => {
  const [clickedPos, setClickedPos] = useState({ x: 0, y: 0 });

  const { gl, scene, camera } = useThree();

  const ref = useRef();
  const material = useRef();
  const delta = 0.03;

  useEffect(() => {
    let animationGsap = null;

    if (isAnimation) {
      if (!visible) {
        const progressValue = { opacity: 1.0 };
        animationGsap = gsap.to(progressValue, {
          duration: 0.8,
          delay: 0.02,
          opacity: 0.0,
          ease: Cubic.easeInOut,
          onUpdate: () => {
            const presphereVector = new THREE.Vector3(
              offset[0],
              offset[1],
              offset[2]
            );
            const pos = presphereVector.lerp(
              new THREE.Vector3(0, 0, 0),
              progressValue.opacity
            );
            ref.current.position.copy(pos);
            _.set(material, "current.opacity", progressValue.opacity);
          },
          onComplete: () => {
            ref.current.visible = false;
            removeSphere();
          },
        });
      } else {
        const progressValue = { opacity: 0.0 };
        animationGsap = gsap.to(progressValue, {
          duration: 0.8,
          opacity: 1.0,
          delay: 0.02,
          ease: Cubic.easeInOut,
          onUpdate: () => {
            const presphereVector = new THREE.Vector3(
              offset[0],
              offset[1],
              offset[2]
            );
            const pos = presphereVector.lerp(
              new THREE.Vector3(0, 0, 0),
              progressValue.opacity
            );
            ref.current.position.copy(pos);
            _.set(material, "current.opacity", progressValue.opacity);
          },
          onComplete: () => {
            onAnimationEnd();
          },
        });
      }
    } else {
      const progressValue = { opacity: 0.0 };
      animationGsap = gsap.to(progressValue, {
        duration: 0.8,
        opacity: 1.0,
        delay: 0.02,
        ease: Cubic.easeInOut,
        onUpdate: () => {
          ref.current.position.copy(new THREE.Vector3(0, 0, 0));
          _.set(material, "current.opacity", progressValue.opacity);
        },
        onComplete: () => {
          onAnimationEnd();
        },
      });
    }

    return () => {
      if (animationGsap) animationGsap.kill();
    };
  }, []);

  const onPointerDownSelf = (e) => {
    setClickedPos({ x: e.clientX, y: e.clientY });
  };

  const onPointerUpSelf = (e) => {
    if (clickedPos.x === e.clientX && clickedPos.y === e.clientY) {
      if (onPointerDown) onPointerDown();
    }
  };

  return (
    <mesh
      ref={ref}
      rotation={rotation}
      scale={[1, 1, -1]}
      onPointerMove={setCurrentPoint}
      onPointerDown={onPointerDownSelf}
      onPointerUp={onPointerUpSelf}
    >
      <sphereBufferGeometry attach="geometry" args={[500, 128, 128]} />
      <meshBasicMaterial
        ref={material}
        attach="material"
        map={texture}
        side={DoubleSide}
        // wireframe={true}
        transparent
        opacity={opacity - 0.01}
        depthWrite={false}
      />
    </mesh>
  );
};

Sphere.propTypes = {};

export default Sphere;
