import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { getNetworkDownloadSpeed } from './utils'
import { LoadingPage, VRPage, ProductPage, NavigationPage, AllProductsPage, ShowRoomPage, AnimationPage } from './pages'
import { Player } from './components'
const whyDidYouRender = require('@welldone-software/why-did-you-render')
whyDidYouRender(React, { trackAllPureComponents: false, logOnDifferentValues: true })

const CustomRoute = (props) => {
  return <Route {...props} />
}
const App = () => {
  useEffect(() => {
    getNetworkDownloadSpeed()
  }, [])
  return (
    <Router>
      <Player />
      <React.Suspense fallback={<LoadingPage redirect={false} />}>
        <Switch>
          <CustomRoute exact component={AllProductsPage} path='/products/:actId' useChat />
          <CustomRoute exact component={ProductPage} path='/product/:id' useChat />
          {/* <CustomRoute exact component={VRPage} path='/vr/:model/:pano' useChat /> */}
          <CustomRoute exact component={NavigationPage} path='/navigation' />
          <CustomRoute exact component={AnimationPage} path='/animation' />
          <CustomRoute exact component={ShowRoomPage} path='/vr' useChat />
          <CustomRoute exact component={LoadingPage} path='/' />
        </Switch>
      </React.Suspense>
    </Router>
  )
}
export default React.memo(App)
