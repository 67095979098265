import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { Html } from '@react-three/drei'
import styled from 'styled-components'

import { VideoSpot } from '../../../assets'

const Video = styled(Html)`
  background-color: rgb(0 0 0 / 75%);
  height: auto;
  min-width: 500px;
  max-height: 350px;
  padding: 8px;
  position: relative;
  right: 0px;
  bottom: 0px;
  > div {
    height: 100vh;
    max-height: 324px;
  }
`
const VideoInfoSpot = ({ position, scale, hover, video, setCursor }) => {
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  const [texture, setTexture] = useState(null)

  const pScale = hovered ? [-40, 40] : [-30, 30]

  useEffect(() => {
    const loader = new THREE.TextureLoader()
    loader.load(VideoSpot, (t) => {
      setTexture(t)
    })
  }, [])

  const onOverHotspot = () => {
    setCursor('pointer')
    setHover(true)
  }

  const onOutHotspot = () => {
    setCursor('auto')
    setHover(false)
  }

  return (
    <group position={position}>
      <sprite
        scale={pScale}
        renderOrder={2}
        onPointerDown={() => setActive(!active)}
        onPointerOver={onOverHotspot}
        onPointerOut={onOutHotspot}
      >
        <spriteMaterial attach='material' map={texture} transparent />
      </sprite>
      {active && (
        <mesh>
          <Video scaleFactor={1000} zIndexRange={[1, 0]}>
            <div dangerouslySetInnerHTML={{ __html: video }} />
          </Video>
        </mesh>
      )}
    </group>
  )
}

VideoInfoSpot.propTypes = {}

export default VideoInfoSpot
