if (window.webXR === undefined) window.webXR = { enabled: false }

const showWebXRNotFound = () => { console.log('VR NOT SUPPORTED') }
const unavailableXR = () => { (window.isSecureContext === false ? console.log('WEBXR NEEDS HTTPS') : console.log('WEBXR NOT AVAILABLE')) }

const onXRSessionStarted = (session) => {
  window.webXR.renderer.xr.setSession(session)
  window.webXR.renderer.xr.enabled = true
  window.webXR.currentSession = session
  // console.log(window.webXR.renderer.xr.getCamera(window.camera));
  window.webXR.renderer.xr.isPresenting && window.webXR.renderer.xr.getCamera(window.camera)
}
export const endXRSession = (onClick = () => { }) => {
  if (!window.webXR?.renderer?.xr) return
  window.webXR.renderer.xr.enabled = false
  if (!window.webXR.currentSession) return
  window.webXR.currentSession.end().then(() => {
    window.webXR.currentSession = undefined
    onClick && onClick()
  })
}

const createXRSession = () => {
  if (window.webXR.currentSession !== undefined) return console.log('XR Already Created!')
  // WebXR's requestReferenceSpace only works if the corresponding feature was requested at session creation time. For simplicity, just ask for
  // the interesting ones as optional features, but be aware that the requestReferenceSpace call will fail if it turns out to be unavailable.
  // ('local' is always available for immersive sessions and doesn't need to be requested separately.)
  const sessionInit = { optionalFeatures: ['local-floor'] }
  navigator.xr.requestSession('immersive-vr', sessionInit).then((session) => { onXRSessionStarted(session) })
}

class VRButton {
  static createXR (renderer) {
    window.webXR.renderer = renderer
    if (!('xr' in navigator)) return unavailableXR()
    if (!window.webXR.vrButton) window.webXR.vrButton = document.createElement('button')
    const button = window.webXR.vrButton
    button.id = 'VRButton'
    navigator.xr.isSessionSupported('immersive-vr').then((supported) => { (supported ? createXRSession() : showWebXRNotFound()) })
  }
}

window.webXR.onXRSessionStarted = onXRSessionStarted
window.webXR.createXRSession = createXRSession
window.webXR.endXRSession = endXRSession
export { VRButton }
