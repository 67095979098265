import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RenderMap, isMobile } from '../../utils'
import { useHistory } from 'react-router'
import { updateProgress } from '../../effects/actions'
import { Row, Col, Tooltip } from 'antd'
import { ExpandMapIcon } from '../icons'

const acts = require('./../../assets/mock-data/act.json')
const Compass = React.memo(() => (
  <div
    ref={(r) => (window.compass = r)}
    className='compass-container no-touch absolute'
  >
    <div className='compass-radar no-touch' />
    <div className='compass-radar' />
    <div className='inner-circle' />
  </div>
))

function LeftMap ({ currentAct, setPointInfo, progress }) {
  const history = useHistory()
  const [expanded, setExpand] = React.useState(false)
  const [currentProduct, setActiveProduct] = React.useState()
  const [selectedAct, setAct] = React.useState(currentAct)
  const activeAct = (item) => (selectedAct.act === item.act ? 'active' : '')
  React.useEffect(() => {
    setAct(currentAct)
  }, [currentAct])

  if (!selectedAct) return null

  return (
    <>
      <MapDiv
        className={`absolute expanded d-flex justify-content-center opacity-animation ${
          expanded ? '' : 'hidden-delay'
        }`}
      >
        <PanelDiv>
          <Row className='h-100'>
            <div onClick={() => setExpand(false)} className='expand-icon'>
              <ExpandMapIcon />
            </div>
            <Col md={14} sm={24} className='pr-3 tabs-panel-50'>
              <Row className='map-row'>
                <Col span={24} className='map-zone container-map'>
                  <Compass />
                  <RenderMap
                    hoverProduct
                    map={selectedAct.act}
                    height='100%'
                    width='100%'
                    currentProduct={currentProduct}
                    setActiveProduct={setActiveProduct}
                    history={history}
                    setPointInfo={setPointInfo}
                  />
                </Col>
              </Row>

              <nav role='navigation' className='main-nav' id='main-nav'>
                <ul id='main-nav-list'>
                  {acts.map((act, i) => (
                    <li
                      onClick={() => setAct(act)}
                      key={i}
                      className={activeAct(act)}
                    >
                      <Tooltip title={act.title}>
                        <span>{act.tab}</span>
                      </Tooltip>
                    </li>
                  ))}
                </ul>
              </nav>
            </Col>

            <Col
              md={10}
              sm={24}
              className='tabs-panel-50 m-overflow-auto mt-3 pb-4 mh-100'
            >
              <Row className='overflow-auto pr-1 pl-1 h-100'>
                {currentProduct && (
                  <div className='mb-3'>
                    <div className='text cataraman size-14 text-grey current-product'>
                      {currentProduct.product}
                    </div>
                    <div className='text cataraman size-30 pb-1 text-grey mb-2 current-brand'>
                      {currentProduct.brand}
                    </div>
                    <div className='mb-1 w-100 text-center mh-200px'>
                      <img
                        className='mh-200px'
                        src={`/products/${currentProduct.id}_2.png`}
                        alt='product'
                      />
                    </div>
                    <hr className='mt-05' />
                    <div
                      className='mb-1 description-text'
                      dangerouslySetInnerHTML={{
                        __html: currentProduct.description
                      }}
                    />
                    <div
                      className='mb-1 text-grey'
                      dangerouslySetInnerHTML={{
                        __html: currentProduct.material
                      }}
                    />
                    <hr className='text white hr-white' />
                    <span className='text din mb-5 text-grey'>
                      {currentProduct.dimensions} |{' '}
                      {currentProduct.dimensionslabel}
                    </span>
                  </div>
                )}
                {!currentProduct && (
                  <div className='mb-3'>
                    <div className='text cataraman size-30 text-grey mb-2 title-act pb-1'>
                      {selectedAct.tab}
                    </div>
                    <div className='text din size-14 text-grey'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedAct.description
                        }}
                      />
                    </div>
                  </div>
                )}
                {!isMobile() && (
                  <Row align='bottom ml-1'>
                    <PanelProgressBar progress={progress}>
                      <p>{progress}%</p>
                    </PanelProgressBar>
                  </Row>
                )}
              </Row>
            </Col>
            {isMobile() && (
              <Row align='bottom ml-1 w-100'>
                <PanelProgressBar progress={progress}>
                  <p>{progress}%</p>
                </PanelProgressBar>
              </Row>
            )}
          </Row>
        </PanelDiv>
      </MapDiv>

      <MapDiv
        className={`d-flex flex-row opacity-animation ${
          expanded ? 'hidden-delay' : ''
        }`}
      >
        <div id='left' className='relative'>
          {isMobile() && (
            <MapZone className='compass-mobile'>
              <Compass />
              <div className='compass-texts'>
                <p className='compass-title'>{currentAct.tab}</p>
                <p className='compass-subtitle'>{currentAct.title}</p>
              </div>
              <div
                onClick={() => setExpand(true)}
                className='button-maximizar'
              />
            </MapZone>
          )}

          {!isMobile() && (
            <MapZone className='compass-web'>
              <div className='image-container'>
                <RenderMap
                  hoverProduct={false}
                  map={currentAct.act}
                  height='140px'
                  width='100%'
                  history={history}
                  setPointInfo={setPointInfo}
                />
                <Compass />
              </div>
              <div className='right-text-container'>
                <div className='top-texts'>
                  <p className='compass-title'>{currentAct.tab}</p>
                  <p className='compass-subtitle'>{currentAct.title}</p>
                </div>

                <div className='bottom-texts'>
                  <p className='compass-title'>{progress}%</p>
                  <p className='compass-subtitle'>Completed</p>
                </div>
              </div>
              <div
                onClick={() => setExpand(true)}
                className='button-maximizar'
              />
            </MapZone>
          )}
        </div>
      </MapDiv>
    </>
  )
}

const mapStateToProps = ({ visitorAPI }) => {
  const { progress } = visitorAPI
  return { progress }
}
export default connect(mapStateToProps, updateProgress)(React.memo(LeftMap))

export const MapZone = styled.div`
  margin: 16px;
  z-index: 2;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: inherit;
  margin: 0 auto;

  @media (max-width: 767px) {
    height: 65px;
    width: 170px;
    display: flex;

    .compass-container {
      background-color: rgb(255 255 255 / 80%);
      width: 65px;
      height: 65px;
      position: relative;
    }

    .compass-texts {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 10px;
      text-transform: uppercase;
      color: #545454;

      .compass-title {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        letter-spacing: 0.5px;
        color: #fff;
        font-family: "Catamaran", sans-serif !important;
      }

      .compass-subtitle {
        font-size: 0.7rem;
        padding: 0;
        margin: 0;
        line-height: 9px;
        letter-spacing: 0.5px;
        font-family: "Catamaran", sans-serif !important;
      }
    }

    .button-maximizar {
      position: absolute;
      z-index: 3;
      cursor: pointer;
      left: 64px;

      &:after {
        position: absolute;
        content: "";
        top: 5px;
        right: 4px;
        width: 9px;
        height: 9px;
        border-top: solid 2px rgb(140, 140, 140, 0.9);
        border-right: solid 2px rgb(140, 140, 140, 0.9);
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    background-color: rgb(244 244 244 / 85%);
    width: 369px;
    height: 200px;

    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      height: 190px;
      transition: all 0.2s ease;
    }

    .right-text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 38px;
      padding-bottom: 22px;
      padding-left: 10px;

      .top-texts {
        display: flex;
        height: 100%;
        flex-direction: column;
        padding-left: 10px;
        text-transform: uppercase;
        color: #c4c4c4;

        .compass-title {
          font-size: 1.1rem;
          padding: 0;
          margin: 0;
          letter-spacing: 0.6px;
          margin-bottom: 8px;
          color: #545454;
          font-family: "Catamaran", sans-serif !important;
        }

        .compass-subtitle {
          font-size: 0.8rem;
          padding: 0;
          margin: 0;
          line-height: 9px;
          letter-spacing: 0.9px;
        }
      }

      .bottom-texts {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 10px;
        color: #545454;

        .compass-title {
          font-size: 1rem;
          padding: 0;
          margin: 0;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #545454;
          font-family: "Catamaran", sans-serif !important;
        }

        .compass-subtitle {
          font-size: 0.7rem;
          padding: 0;
          margin: 0;
          line-height: 9px;
          letter-spacing: 0.9px;
          color: #545454;
        }
      }
    }

    .button-maximizar {
      position: absolute;
      z-index: 3;
      cursor: pointer;
      right: 0;
      top: 0;

      &:after {
        position: absolute;
        content: "";
        top: 9px;
        right: 9px;
        width: 11px;
        height: 11px;
        border-top: solid 2px rgb(140, 140, 140, 0.9);
        border-right: solid 2px rgb(140, 140, 140, 0.9);
      }
    }
  }
`
export const MapDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-flow: nowrap;
  margin: 24px;
  color: #fff;
  font-family: "DIN2014";
  bottom: 0;
  z-index: 11;

  &.expanded {
    z-index: 11;
    @media (max-width: 768px) {
      margin: 12px;
    }
    @media (min-width: 768px) {
      overflow: hidden;
      height: 100vh;
      min-height: 500px;
      margin: 0 85px;
    }

    @media (min-width: 1400px) {
      min-height: 780px;
    }
  }

  #left {
    height: 100%;
    width: 100%;
    @media (max-width: 767px) {
      .compass-web {
        display: none;
      }
    }

    @media (min-width: 768px) {
      .compass-mobile {
        display: none;
      }
    }
  }

  #right {
    height: 100%;
    width: 120px;
    width: 250px;
    height: 150px;
    bottom: 16px;
    left: 16px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
export const PanelDiv = styled.div`
    position: absolute;
    padding: 20px;
    @media (max-width: 767px) {padding:12px; padding-bottom:24px} 
    z-index:11;
    background-color: rgb(244 244 244 / 92%);
    max-width: 1360px;
    backdrop-filter: blur(2px);

    .expand-icon{z-index:11;position:absolute;left:unset;bottom:unset;right:6px;top:6px;svg{height:16px;width:16px}}

    .tabs-panel-50{
        height:100%;
        @media (max-width: 767px) { 
        height: calc(50% - 12px);
        padding-right:0!important;
        padding-bottom:0!important;
        &.m-overflow-auto{overflow:auto}
    }}

    .map-row{ height: calc(100% - 50px) }
    .map-row{ @media (min-width: 767px) { height: calc(100% - 30px) }}
    
    .text-grey{
        color: #545454;
    }

    .current-product{
        text-transform: capitalize; 
    }
    .description-text{
        color: #545454;
        font-family:'DIN2014';
        font-size. 16px;
    }
    .title-act, .current-act{
        font-family: 'Catamaran', sans-serif !important;
        font-weight: bold;
    }

    @media (max-width: 767px) {
        height: calc(100vh - 24px); 
        overflow: hidden;

        .title-act{
            text-align: center;
            font-family: 'Catamaran', sans-serif !important;
        }
        .current-act{
            display: none;
        }
    }

    @media (min-width: 768px) { 
        height: calc(100vh - 32px); 
        max-height: 450px; 
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    @media (min-width: 1400px) { 
        max-height: 600px;
    }
`
export const PanelProgressBar = styled.div`
    position: relative;
    margin-top: 10px;
    margin-left: 5px;
    width: 100%;
    border-bottom: solid 1px #fff;
    height: 1px;

    @media (min-width: 768px) {
            position: absolute;
            bottom: 14px;
            left: 0;
        }
    }

    p {
        position: absolute;
        top:2px;
        width: 40px;
        text-align: center;
        transform: translateX(-50%);
        color: #339966;
        left: ${(p) => `${p.progress}%`};
        transition: all .2s ease;
    }

    &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -1px;
        width: ${(p) => `${p.progress}%`};
        height: 1px;
        background: #339966;
        transition: all .2s ease;
    }
`
