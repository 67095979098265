import React from 'react'
import PropTypes from 'prop-types'

const BackIcon = ({ stroke }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'>

      <g id='exit_-_on_white' data-name='exit - on white' transform='translate(0.5 0.5)'>
        <g id='Group_283' data-name='Group 283' transform='translate(-76 -56)'>
          <path d='M.007,6.334H0l0,0,0,0H.007L6.451.108,7,.706,1.18,6.327,7,12.009l-.548.6Z' transform='translate(82.499 60.002)' />
        </g>
      </g>
    </svg>
  )
}

BackIcon.propTypes = {
  stroke: PropTypes.string
}

BackIcon.defaultProps = {
  stroke: '#2a2a2a'
}

export { BackIcon }
