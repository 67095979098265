import React from 'react'

const HotspotProductIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
      <g id='Group_538' data-name='Group 538' transform='translate(-1403 -655)'>
        <g id='Rectangle_834' data-name='Rectangle 834' transform='translate(1403 655)' fill='rgba(255,255,255,0.8)' stroke='#8c8c8c' strokeWidth='1'>
          <rect width='30' height='30' rx='15' stroke='none' />
          <rect x='0.5' y='0.5' width='29' height='29' rx='14.5' fill='none' />
        </g>
        <path id='Path_1449' data-name='Path 1449' d='M-246.073,488.606v4.483a4.266,4.266,0,0,0-2.792-1.038,4.287,4.287,0,0,0-4.287,4.287,4.287,4.287,0,0,0,4.287,4.287,4.266,4.266,0,0,0,2.792-1.038v.838h1.981V488.606Zm-2.373,10.207a2.475,2.475,0,0,1-2.475-2.475,2.475,2.475,0,0,1,2.475-2.475,2.475,2.475,0,0,1,2.475,2.475A2.475,2.475,0,0,1-248.446,498.813Z' transform='translate(1661.527 174.384)' fill='#8c8c8c' stroke='#8c8c8c' strokeMiterlimit='10' strokeWidth='0.5' />
        <path id='Path_1450' data-name='Path 1450' d='M-213.3,495.919l3.535-3.868h-2.485l-3.414,3.7h-.147v-7.141h-1.98v11.819h1.98v-3.435h.7l2.893,3.435h2.626Z' transform='translate(1636.675 174.384)' fill='#8c8c8c' stroke='#8c8c8c' strokeMiterlimit='10' strokeWidth='0.5' />
      </g>
    </svg>
  )
}

export { HotspotProductIcon }
