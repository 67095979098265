import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
// import { useSpring, animated } from "@react-spring/three";
import { useSpring, animated } from "@react-spring/three";
import { useFrame, useThree } from "@react-three/fiber";

import { Productspot } from "../../../assets";

const ProductInfoSpot = ({
  position,
  hoverScale,
  scale,
  to,
  history,
  setCursor,
  backupCamera,
}) => {
  const ref = useRef();
  const { camera } = useThree();

  const [hovered, setHover] = useState(false);
  const [texture, setTexture] = useState(null);

  // const pScale = hovered ? [-40, 40] : [-30, 30];

  const { pScale } = useSpring({
    pScale: hovered ? [-40, 40] : [-30, 30],
    config: { duration: 200 },
  });

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.load(Productspot, (t) => {
      setTexture(t);
    });
  }, []);

  const onClickHotspot = () => {
    backupCamera([camera.position.x, camera.position.y, camera.position.z]);
    to && history && history.push(to, history.location.pathname);
  };

  const onOverHotspot = () => {
    setCursor("pointer");
    setHover(true);
  };

  const onOutHotspot = () => {
    setCursor("auto");
    setHover(false);
  };

  return (
    <>
      {texture && (
        <animated.sprite
          ref={ref}
          position={position}
          scale={pScale}
          renderOrder={2}
          onPointerOver={onOverHotspot}
          onPointerOut={onOutHotspot}
          onPointerDown={onClickHotspot}
        >
          <spriteMaterial attach="material" map={texture} transparent />
        </animated.sprite>
      )}
    </>
  );
};

ProductInfoSpot.propTypes = {};

export default ProductInfoSpot;
