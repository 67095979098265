import React from 'react'
import SpeedTester from 'browser-speed-test'
import { ActMap00, ActMap0, ActMap1, ActMap2, ActMap3, ActMap4, ActMap5, ActMapFull } from '../components'
import { store } from './../effects/store'
import { updateNetworkSpeed } from './../effects/actions'

export const isMobile = () => (window.innerWidth < 811)

export const mobileClass = (string = '') => {
  return `${string}${isMobile() ? ' mobile' : ''}`.trim()
}

export function eventFire (el, etype) {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    const evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

export const getNetworkDownloadSpeed = async () => {
  const tester = new SpeedTester({ url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/502px-Google_2015_logo.svg.png' })
  const speed = await tester.start()
  store.dispatch(updateNetworkSpeed(speed))
}

export const Util = {
  renderMessage: (message) => {
    switch (message) {
      case 'internal_error':
        return 'Internal error, try again.'

      case 'email_or_password_invalid':
        return 'Invalid email or password.'

      case 'email_invalid':
        return 'Invalid email.'

      case 'password_invalid':
        return 'Invalid password.'

      case 'error.user_exists':
        return 'Email already exists.'

      default:
        return 'Internal error, try again.'
    }
  },
  getErrorLogin (error) {
    const errorResponse = error
    errorResponse.data = 'internal_error'
    if (error.data !== undefined) {
      if (error.data.uidField === undefined) {
        errorResponse.data = 'email_invalid'
      } else {
        errorResponse.data = 'password_invalid'
      }
    }
    return errorResponse
  },
  getError (error) {
    const errorResponse = error
    errorResponse.response.data = 'internal_error'
    return errorResponse
  },
  getBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
}

export const RenderMap = React.memo(({ hoverProduct, map, height, width, currentProduct, setActiveProduct, history, setPointInfo }) => {
  switch (map) {
    case '00': return <ActMap00 hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />
    case '0': return <ActMap0 hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />
    case '1': return <ActMap1 hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />
    case '2': return <ActMap2 hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />
    case '3': return <ActMap3 hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />
    case '4': return <ActMap4 hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />
    case '5': return <ActMap5 hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />
    case 'full': return <ActMapFull hoverProduct={hoverProduct} height={height} width={width} currentProduct={currentProduct} setActiveProduct={setActiveProduct} setPointInfo={setPointInfo} history={history} />

    default:
      return null
  }
})
