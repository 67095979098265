import { VisitorActionType } from './../../types'

export const postVisitorAPISuccess = (response) => ({
  type: VisitorActionType.POST_VISITOR_API_SUCCESS,
  payload: response
})

export const postVisitorAPIFailure = (
  error
) => ({
  type: VisitorActionType.POST_VISITOR_API_FAILURE,
  payload: error
})

export const postVisitorAPI = (
  data
) => ({
  type: VisitorActionType.POST_VISITOR_API,
  payload: data
})

export const updateVisitorInput = (
  property, value
) => ({
  type: VisitorActionType.UPDATE_VISITOR_INPUT,
  payload: { property, value }
})

export const updateToken = (
  token
) => ({
  type: VisitorActionType.UPDATE_TOKEN,
  payload: token
})

export const updateProgress = (
  act, percent
) => ({
  type: VisitorActionType.UPDATE_PROGRESS,
  payload: { percent, act }
})
