import React from 'react'

const ForwardToSNIcon = ({ fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'>
      <path id='Path_223' data-name='Path 223' d='M1825.8,433.436l2.238,5.513L1834,425.028Zm7.529-9.487L1819,430.423l5.969,2.1Z' transform='translate(-1819 -423.949)' fill={fill} />
    </svg>
  )
}

export default ForwardToSNIcon
